import { useState, useEffect, useRef } from 'react';
import className from 'classnames/bind';
import styles from './Header.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSearch, faHouseFlag, faClose, faSignOut, faHome } from '@fortawesome/free-solid-svg-icons';
import Slidebar from '~/Components/Layouts/MapsLayout/Slidebar';
import GoogleMap from '~/Components/GoogleMap';
import Note from '~/Components/Note';
import Folders from '~/Api/Folders';
import Feature from '~/Api/Feature';
import Role from '~/Api/Role';
import FormLogin from '~/Components/FormLogin';
import ChangeMap from '~/Components/ChangeMap';
import proj4 from 'proj4';
import Toast from '~/Components/Controls/Toast';
import Register from '~/Components/Register';
import Crypto from '~/Function/Crypto';
import Search from '~/Api/Search';

const css = className.bind(styles);

function Header({comeback, openMap}) {
    const feature = new Feature();
    const loginRef = useRef(null);
    const formSearchRef = useRef(null);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [isHovered, setIsHovered] = useState(false);
    const [classBox, setClassBox] = useState('close-search');
    const [openBars, setOpenBars] = useState(false);
    const [dataUpload, setDataUpload] = useState([]);
    const [geojsonData, setGeojsonData] = useState([]);
    const [layerMap, setLayerMap] = useState('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=vi&gl=vn');
    const [listSearch, setListSearch] = useState([]);
    const [latlng1, setLatLng] = useState(null);
    const [listFeatureId, setListFeatureId] = useState([]);
    const [listIdFile, setListIdFile] = useState([]);
    const [isOpenRegister, setIsOpenRegister] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [userId, setUserId] = useState(0);
    const [isCross, setIsCross] = useState(false);
    const [radioValue, setRadioValue] = useState('');

    const handleRadioValue = (value) => {
        setRadioValue(value);
    };

    const handleAuthen = () => {
        setIsHovered(!isHovered);
    };

    function changePostion(value) {
        if (value[0] > 1000.0 && value[0] > 1000.0) {
            proj4.defs('EPSG:4326', '+proj=longlat +datum=WGS84 +no_defs');
            proj4.defs('EPSG:32648', '+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs');
            const finalCoords = proj4('EPSG:32648', 'EPSG:4326', [value[1], value[0]]);
            return [finalCoords[1], finalCoords[0]];
        } else {
            return value;
        }
    }

    const handleOpenBars = () => {
        setOpenBars(true);
        // const folders = new Folders();
        // if (dataUpload.length <= 0) {
        //     folders
        //     .getListFolders()
        //     .then((data) => setDataUpload(data.data));
        // }
    };

    const handleComeback = () => {
        comeback(false);
    };

    const handleChangeMap = (data) => {
        setLayerMap(data);
    };

    const handleCloseBars = () => {
        setOpenBars(false);
        // setListSearch('');
        // setClassBox('close-search');
        // setDataUpload([]);
    };

    const [inputValue, setInputValue] = useState('');

    const handleChange = (event) => {
        setInputValue(event.target.value);
        if(event.target.value === ''){
            setListSearch('');
            setClassBox('close-search');
        } else {
            const search = new Search;
            search.getSearchByKeyword(event.target.value).then((response) => {
                setListSearch(response.data);
                if (response.data.length > 0) {
                    setClassBox('open-search');
                }
            });
        }
    };
    
    const handleOnClickInput = () =>{
        if(inputValue === ''){
            setListSearch('');
            setClassBox('close-search');
        } else {
            const search = new Search;
            search.getSearchByKeyword(inputValue).then((response) => {
                setListSearch(response.data);
                setClassBox('open-search');
            });
        }
    };

    const [numberData, setNumberData] = useState([]);
    const handleSliderDataChange = (data) => {
        setListFeatureId(data);
    };

    const handleClickItem = (point) => {
        if(point){
            const cleanedString = point.replace(/[() ]/g, '');
            const coordinatesArray = cleanedString.split(',');
            const coordinates = coordinatesArray.map((value) => parseFloat(value));
            setShowToast(true);
            setMessage('Đang thay đổi vị trí.');
            setTypeToast('success');
            setLatLng(changePostion([coordinates[1],coordinates[0]]));
        }
    };

    const handleGetListIdFile = (data) => {
        setListIdFile(data);
    };

    const handleRefresh = (event) => {
        setGeojsonData([]);
        setNumberData([]);
        const listData = [];
        if(listFeatureId){
            listData.push(geojsonData);
            listFeatureId.map((res) => {
                feature.getFeatureByFolderId(res)
                .then((d)=>{
                    setNumberData(listFeatureId);
                    setGeojsonData(d.data);
                });
            });
            setGeojsonData(listData);
        }
    };

    const handleLoginRef = (event) => {
        if (loginRef.current && !loginRef.current.contains(event.target)) {
            setIsHovered(false);
        }

        if(formSearchRef.current && !formSearchRef.current.contains(event.target)){
            setListSearch([]);
            setClassBox('close-search');
        }
    };

    const [showToast, setShowToast] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleEnterInput = (event) => {
        if (event.key === 'Enter') {
            if (listSearch.length > 0) {
                const cleanedString = listSearch[0].point.replace(/[() ]/g, '');
                const coordinatesArray = cleanedString.split(',');
                const coordinates = coordinatesArray.map((value) => parseFloat(value));
                setShowToast(true);
                setMessage('Đang thay đổi vị trí.');
                setTypeToast('success');
                setLatLng(changePostion([coordinates[1],coordinates[0]]));
            }
        }
    };

    const handleCloseRegister = (data) => {
        if(data){
            setIsHovered(false);
        }
        setIsOpenRegister(data);
    };

    const handleCloseNote = (data) => {
        if(data) {
            setIsHovered(false);
        } 
    }

    const handleOpenLogin = (data) => {
        setIsLogin(data);
        setIsHovered(false);
    };

    const handleLogOut = () => {
        setIsLogin(false);
        localStorage.setItem('_ConcurrencyStamp','');
        localStorage.setItem('_userName','');
        localStorage.setItem('_token','');
        localStorage.setItem('_Id','');
    };

    const handleGetUserId = (data) => {
        setUserId(data);
    };

    const handleCross = (data) => {
        setIsCross(data);
    };

    useEffect(() => {
        const folders = new Folders();
        if (dataUpload.length <= 0) {
            folders
            .getListFolders()
            .then((data) => setDataUpload(data.data));
        }
        if(!isLogin) {
            localStorage.setItem('_ConcurrencyStamp','');
            localStorage.setItem('_userName','');
            localStorage.setItem('_token','');
            localStorage.setItem('_Id','');
        }
        const role = new Role();
        const crypto = new Crypto();
        role.getConcurrencyStampAdmin()
        .then((response) => {
            if(response.status === 200){
                let concurrencyStamp = crypto.encryptData(response.data.concurrencyStamp);
                localStorage.setItem("_admin",concurrencyStamp);  
            }
        });
        role.getConcurrencyStampOperator()
        .then((response) => {
            if(response.status === 200){
                let concurrencyStamp = crypto.encryptData(response.data.concurrencyStamp);
                localStorage.setItem("_operator",concurrencyStamp);
            };
        });

        document.addEventListener('mousedown', handleLoginRef);
        return () => {
            document.removeEventListener('mousedown', handleLoginRef);
        };
    }, []);

    useEffect(() => {
        const handleContextmenu = (e) => {
            e.preventDefault();
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, []);

    return (
        <>
            <div>
                <div className={css('search')} ref={formSearchRef}>
                    <div className='bootstrap'>
                        {window.innerWidth > 430 && (
                            <>
                                <div className={css('input-search', classBox)}>
                                    <div style={{ display: 'flex', width: "auto", paddingRight: "8%" }}>
                                        <div className={css('btn-nav')}>
                                            {/* <FontAwesomeIcon className={css('icon-bars')} icon={faBars} /> */}
                                        </div>
                                        <input
                                            type="input"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            onClick={handleOnClickInput}
                                            style={{ fontSize: '1.5rem', fontFamily: 'Roboto,Arial,sans-serif' }}
                                            className={css('form-search')}
                                            value={inputValue}
                                            onChange={handleChange}
                                            onKeyPress={(e) => handleEnterInput(e)}
                                            placeholder="Tìm kiếm"
                                        />
                                        <div className={css('btn-search')}>
                                            <FontAwesomeIcon className={css('icon-search')} icon={faSearch} />
                                        </div>
                                        <div className={css('btn-search')} style={{marginLeft: '1rem'}} onClick={handleComeback}>
                                            <FontAwesomeIcon className={css('icon-search')} icon={faHome} />
                                        </div>
                                    </div>
                                </div>
                                {listSearch.length > 0 && (
                                    <div class={css('box-item-search')}>
                                        {listSearch.map((res) => (
                                            <div className={css('box-item')} onClick={() => handleClickItem(res.point)}>
                                                <div style={{ display: 'flex' }}>
                                                    <FontAwesomeIcon className={css('icon-flag')} icon={faHouseFlag} />
                                                    <p className={css('box-item-title')}>{res.name}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
                {window.innerWidth > 430 && (
                    <div className={css('map-header')} ref={loginRef}>
                        <div className="bootstrap">
                            <div className={css('head_authen')}>
                                {isLogin && (
                                    <button className={css('btn-logout')} onClick={handleLogOut}><FontAwesomeIcon icon={faSignOut}/> Đăng xuất</button>
                                )}
                                {isLogin === false && (
                                    <img className={css('authenIcon')} src='/Assets/Images/login.png' onClick={handleAuthen}/>      
                                )}
                                {isHovered && (<FormLogin isOpenRegister={handleCloseRegister} isLogin={handleOpenLogin} userId={handleGetUserId}/>)}
                            </div>
                        </div>
                    </div>
                )}
                {window.innerWidth <= 430 && (
                    <>
                        <div className={css(!openBars?'hidden':'')}>
                            <Slidebar
                                data={dataUpload}
                                onDataChange={handleSliderDataChange}
                                listIdFile={handleGetListIdFile}
                                openBars={openBars}
                                cross={handleCross}
                            />
                            <div className={css('icon-close')} onClick={handleCloseBars}><FontAwesomeIcon icon={faClose} /></div>
                        </div>
                        <div className={css(openBars?'hidden':'open-bars')} onClick={handleOpenBars}>
                                <FontAwesomeIcon className={css('icon-bars')} icon={faBars} />
                            </div>
                        <div className={css('back-home')} onClick={handleComeback}>
                            <FontAwesomeIcon className={css('icon-search')} icon={faHome} />
                        </div>
                    </>
                )}
                {window.innerWidth > 430 && (
                    <Slidebar
                        data={dataUpload}
                        onDataChange={handleSliderDataChange}
                        listIdFile={handleGetListIdFile}
                        openBars={openBars}
                        cross={handleCross}
                        isLogin={isLogin}
                        radioValue={handleRadioValue}
                    />
                )}
            </div>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            <GoogleMap layer={layerMap} onClick={handleCloseBars} latlng={latlng1} refresh={handleRefresh} listIdFile={listIdFile} isLogin={isLogin} userId={userId} openMap={openMap} isCross={isCross} bdld={radioValue}/>
            <ChangeMap onDataChange={handleChangeMap}/>
            <Register isOpen={isOpenRegister} isClose={handleCloseRegister}/>
            <Note isOpenRegister={isHovered} isClose={handleCloseNote}/>
        </>
    );
}

export default Header;