import Header from './Header';
import Introduction from './Introduction';
import { useState } from 'react';

const MapsLayout = ({ children }) => {
    const [openMap, setOpenMap] = useState(false);

    const handleOpenPage = (data) => {
        if(data){
            setOpenMap(true);
        }
    };

    const handleComeback = (data) => {
        if(!data){
            setOpenMap(data);
        }
    };

    return (
        <div>
            {!openMap&&<Introduction openPage={handleOpenPage}/>}
            <Header comeback={handleComeback} openMap={openMap}/>
            <div className="container">
                {/* <Slidebar /> */}
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default MapsLayout;
