import styles from './Section1.module.scss';
import className from "classnames/bind";

const css = className.bind(styles);

function Section1 (){
    return(
        <div className={css('container')}>
            <div className={css('header')}>
                LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
            </div>
            <div className={css('container-logo')}>
                <div className={css('logo')}>
                    <img src="/Assets/Images/Intro/countdown.png"/>
                </div>
            </div>
            <div className={css('content')}>
                <div className={css('heading-1')}>GIỚI THIỆU GÓI THẦU</div>
                <p>Đồng bằng sông Cửu Long (ĐBSCL) là một trong những đồng bằng châu thổ lớn nhất Đông Nam Á. 
                Vùng này nằm ở cuối sông Cửu Long và bao gồm 13 tỉnh và thành phố của Việt Nam, bao gồm Long An, Tiền Giang, Bến Tre, Đồng Tháp, Vĩnh Long, 
                Trà Vinh, Sóc Trăng, An Giang, Kiên Giang, Hậu Giang, Bạc Liêu, Cà Mau và TP Cần Thơ. Với diện tích khoảng 3,95 triệu ha, ĐBSCL chiếm 12% diện 
                tích cả nước và có dân số khoảng 19 triệu người, chiếm 21% dân số cả nước.</p>
                <p>Vùng ĐBSCL đóng vai trò quan trọng đối với sự phát triển kinh tế - xã hội của Việt Nam. 
                Với những đặc điểm độc đáo và thuận lợi như đất đai trù phú, khí hậu nhiệt đới, 
                ĐBSCL thuận lợi cho phát triển nông nghiệp. ĐBSCL đã được gọi là "vựa lúa" của Việt Nam. 
                Trong những năm gần đây, vùng đồng bằng đã đóng góp hơn 50% tổng sản lượng lương thực quốc gia của Việt Nam, 
                90% lượng gạo xuất khẩu của Việt Nam, 70% hoa quả của cả nước, hơn 60% sản lượng nuôi trồng thủy sản cả nước.</p>
                <p>Tuy nhiên, vùng ĐBSCL đang đối mặt với nhiều thách thức. Các thảm họa thiên tai như hạn hán, 
                thiếu nước kèm theo xâm nhập mặn nghiêm trọng, ngập lũ đô thị và ven biển, sạt lở bờ sông và bờ biển, 
                sự biến mất của lũ lụt hàng năm đã gây ảnh hưởng đáng kể đến kinh tế xã hội trong vùng. 
                Những hiện tượng này xuất phát từ nhiều nguyên nhân như biến đổi khí hậu, xây dựng đập trên dòng chính và dòng nhánh 
                của sông Mê Kông, xây đê ngăn lũ, khai thác cát và khai thác quá mức nước ngầm.</p>
                <p>Dưới tác động của sạt lở bờ sông và bờ biển, tình hình trở nên ngày càng nghiêm trọng, 
                trong khi nguồn tài chính của Chính phủ Việt Nam không đủ để đáp ứng yêu cầu thực tế. 
                Vì vậy, Chính phủ đã đề nghị và nhận được tín dụng từ Hiệp hội Phát triển Quốc tế (IDA) 
                của Ngân hàng Thế giới (WB) để tài trợ cho Dự án Tăng cường sinh kế bền vững và thích ứng với biến đổi khí hậu tổng hợp 
                tại ĐBSCL (ICRSL). Dự án này bắt đầu từ năm 2016 với mục tiêu tăng cường khả năng lập kế hoạch thích ứng với tác 
                động của biến đổi khí hậu và cải thiện quản lý, sử dụng đất và tài nguyên nước ở một số tỉnh ĐBSCL.</p>
                <p>Ngoài ra, Dự án Hỗ trợ kỹ thuật cho Dự án sinh kế bền vững và thích ứng với khí hậu tổng hợp ĐBSCL (GEF-ICRSL) 
                đã được Bộ Nông nghiệp và Phát triển nông thôn (NN & PTNT) phê duyệt thông qua Quyết định số 4694/QĐ-BNN-HTQT ngày 16/11/2017. 
                Dự án này sử dụng tài trợ từ Quỹ Môi trường Toàn cầu (GEF) thông qua Ngân hàng Thế giới để nâng cao năng lực nghiên cứu và đổi mới cho các cơ quan nghiên cứu, 
                nhằm phát triển và áp dụng quản lý tài nguyên thiên nhiên, nông, lâm, ngư nghiệp thích ứng với biến đổi khí hậu ở các tỉnh được chọn.</p>
            </div>
        </div>
    );
}

export default Section1;