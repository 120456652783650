import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/feature/';
class Feature {
    // Cấu hình mặc định cho tất cả các yêu cầu Axios
    updateFeatureIconAndColor({formData}){
        return axios.put(path+'update-feature-icon-and-color/',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateBlinking(featureId){
        return axios.put(path+'update-blinking/'+featureId)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getFeatureByFolderId(folderId)
    {
        return axios.get(path+'get-feature-by-folder-id/'+folderId)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFeatureById(Id){
        return axios.get(path+'get-feature-by-id/'+Id)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFeatureByListFolder(Ids){
        return axios.get(path+'get-feature-by-list-folder/'+Ids)
            .then(response => response.data)
            .catch(error => error.response.data);
    };

    getFeatureByListFile(FileId){
        return axios.get(path+'get-feature-by-list-file/'+FileId)
            .then(response => response.data)
            .catch(error => error.response.data);
    }

}

export default Feature;