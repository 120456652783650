import styles from "./Section5.module.scss";
import className from "classnames/bind";

const css = className.bind(styles);

function Section5(){
    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/dam.png"/>
            </div>
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>TUYẾN CHỈNH TRỊ, HÀNH LANG AN TOÀN</div>
            <p>Hành lang an toàn lâu dài ven sông để cho phép dòng sông tự điều chỉnh trong phạm vi cho phép đối với 
                sông Cửu Long là một con sông trẻ đang phát triển đã được đặt ra từ lâu, nhưng chưa có lời giải cụ thể. 
                Hơn nữa, hệ thống sông lại chịu nhiều tác động bất lợi, đặc biệt là sự phát triển của cơ sở hạ tầng, dân 
                cư dọc theo các tuyến sông, chưa có sự đồng thuận của các ngành kinh tế liên quan, cho nên cần phải được 
                xem xét điều chỉnh tương ứng với những sự thay đổi này. </p>
            <p>Diễn biến lòng dẫn ứng với các điều kiện biên khác nhau sau 1 năm mô phỏng bằng mô hình MIKE21đã được tính 
                toán cho 8 kịch bản (bao gồm (i): Lưu lượng tạo lòng (KB1); (ii) năm khí hậu điển hình (2017) (KB2); phát triển thượng 
                nguồn (KB3); Khai thác cát (KB4), BĐKH-NBD (KB5); năm lũ và hạn điển hình (KB6-1, KB6-2) và tổ hợp (KB7). Kết quả tính 
                toán đã được so sánh về tổng lượng xói/bồi phân chia theo 4 giai đoạn mùa trong năm, phân chia theo vùng thượng, trung và 
                hạ lưu ĐBSCL và phân chia theo các tỉnh. Kết quả đánh giá đã cho thấy những tác động khác nhau của các kịch bản và đã lựa 
                chọn kịch bản tổng hợp (KB7), phù hợp với những thay đổi trong thực tế nhất (bao gồm năm điển hình, tác động thượng nguồn, khai thác cát, BĐKH-NBD).</p>
            <p>Sử dụng dữ liệu của KB7, tư vấn đã dự báo diễn biến lòng dẫn đến năm 2030 bằng mô hình 
                MIK21 cho toàn bộ sông Tiền và sông Hậu, từ biên giới đến cửa sông. Từ đó, kết hợp với mô hình 
                Geoslope để dự báo sạt lở bờ sông đến năm 2030 để lập bản đồ cảnh báo di dời dân cư dọc theo sông 
                Tiền và sông Hậu đến năm 2030. Kết quả dự báo diễn biến lòng dẫn đến năm 2030 cũng là cơ sở để đề 
                xuất tuyến chỉnh trị và công trình chỉnh trị nhằm ổn định bờ sông, đặc biệt cho 4 khu vực trọng
                 điểm ở ĐBSCL, phục vụ quy hoạch phát triển của các ngành kinh tế xã hội cho ĐBSCL. </p>
            <p>Việc xác định hành lang sạt lở bờ sông đến năm 2030 dựa trên mô hình khái niệm và kinh
                 nghiệm ở ĐBSCL, cần thiết phải được theo dõi và kiểm chứng nhằm điều chỉnh cho phù hợp.
                  Đối với việc cảnh báo sạt lở giai đoạn 2022-2030 cho toàn tuyến sông Tiền, sông Hậu dựa
                   trên “đường bao trên” tương quan chiều sâu xói của tuyến lạch sâu và chiều rộng sạt lở
                    bờ chỉ mang tính chất tham khảo để cảnh báo. Các địa phương cần có số liệu địa hình và
                     địa chất để có thể sử dụng phương pháp dự báo như đã thực hiện tại các trọng điểm.</p>
            <p>Các tuyến chỉnh trị sông ở ĐBSCL và ở các khu vực trọng điểm, về cơ bản, dự án đã đề xuất trên 
                nguyên tắc đảm bảo hình thái sông ổn định và phải mang lại lợi ích tổng hợp cho tất cả các ngành 
                kinh tế có liên quan đến khai thác dòng sông. Đồng thời, cũng để một khoảng không gian cho lòng sông 
                tự điều chỉnh và phục hồi hệ sinh thái ven sông. Đây là một vấn đề tương đối quan trọng, được rút ra từ kinh 
                nghiệm của các nước phát triển, tránh những sai lầm trong quá khứ khi chưa xem xét lợi ích tổng hợp đặc biệt 
                là của hệ sinh thái cảnh quan sông nước. Vì chưa có một “thỏa thuận” nào về lợi ích của các ngành có liên quan, 
                dự án này đã xem xét các bản đồ quy hoạch của các ngành, của các tỉnh có liên quan (sử dụng đất, quy hoạch đô thi, giao 
                thông, thủy lợi, bến/cảng, du lịch, di tích lịch sử v.v…) chồng lớp để xem xét đảm bảo không mâu thuẫn với các quy hoạch của các ngành kinh tế.</p>
        </div>
    </div>);
}

export default Section5;