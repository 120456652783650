import React, { useState, useEffect, useRef } from 'react';
import className from 'classnames/bind';
import styles from './Folder.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faTrash, faAdd, faChevronDown, faEdit, faCheck, faBan } from '@fortawesome/free-solid-svg-icons';
import FolderAPI from '~/Api/Folders';
import Toast from '~/Components/Controls/Toast';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);
function Folder({openButton, closeFolder}){
    const [openFolder, setOpenFolder] = useState(false);
    const [openAddFolder, setOpenAddFolder] = useState(false);
    const [dataFolder, setFolder] = useState([]);
    const [dataUpload, setDataUpload] = useState([]);
    const [folderId, setFolderId] = useState(0);
    const [folderName, setFolderName] = useState('');
    const [idEdit, setIdEdit] = useState(0);
    const [parentName, setParentName] = useState('');
    const [parentId, setParentId] = useState(0);
    const [isOpenListFolder, setIsOpenListFolder] = useState(false);
    const [listDropdown, setListDropDown] = useState([]);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const pageSize = 10;
    const [valueSearch, setValueSearch] = useState('');

    useEffect(() => {
        setOpenFolder(openButton);
        if(openButton){
            const folder = new FolderAPI();
            folder.countFolders()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        }
    },[openButton])

    const handleValueSearch = (event) => {
        setValueSearch(event.target.value);
        let search = event.target.value;
        const folder = new FolderAPI();
        if(search === ''){
            folder.getFoldersByPage(pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setFolder(response.data);
                }
            });
            folder.countFolders()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        } else {
            folder.findFolders(search, pageIndex, pageSize)
            .then(response => {
                if(response.status === 200){
                    setFolder(response.data);
                }
            });
            folder.countFindFolders(search)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    useEffect(() => {
        fetchUsers();
    },[pageIndex]);

    const fetchUsers = async () => {
        const user = new FolderAPI();
        user.getFoldersByPage(pageIndex,pageSize)
        .then(response => {
            if(response.status === 200){
                setFolder(response.data);
            }
        });
        user.countFolders()
        .then(response => {
            if(response.status === 200){
                setTotalItems(response.data);
            }
        });
     };

    const handleOpenForm = () =>{
        setOpenFolder(true);
        const folder = new FolderAPI();
        folder.countFolders()
        .then(response => {
            if(response.status === 200){
                setTotalItems(response.data);
            }
        })  
    };

    const handleOpenEdit = (id) => {
        setIdEdit(id);
        const editingRow = dataFolder.find((res) => res.id === id);
        setFolderName(editingRow?.foldersName || '');
        setParentName(editingRow?.rootName || '');
        setParentId(editingRow?.parentId || 0);
        setIsOpenListFolder(false);

    };

    const handleCloseForm = () =>{
        setOpenFolder(false);
        closeFolder(false);
    };

    const handleOpenAddForm = () =>{
        setOpenAddFolder(true);
        setOpenFolder(false);
        setIdEdit(0);
        setFolderName('');
        setFolderId(0);
    };

    const handleCloseAddForm = () =>{
        setOpenAddFolder(false);
        setOpenFolder(true);
    };

    const [isDropdown, setIsDropdown] = useState(false);
    const [foldersName, setFoldersName] = useState('');

    const buildFolderTree = (data, parentId = 0) => {
    const filteredItems = data.filter((item) => item.rootId === parentId);
        if (filteredItems.length === 0) {
            return [];
        }
        
        const tree = filteredItems.flatMap((item) => {
            const descendants = buildFolderTree(data , item.id);
            return [item, ...descendants];
        });
        
        return tree;
    };
  
    const folderTree = buildFolderTree(dataUpload);

    const handleDropdown = () =>{
        const folders = new FolderAPI();
        folders.getListFolders()
        .then((data)=>{
            setDataUpload(data.data);
        });
        setIsDropdown(!isDropdown);
    }

    const handleFolderIdChange = (item) => {
        setFolderId(item.id);
        setFoldersName(item.foldersName);
        setIsDropdown(false);
    };

    const handleRemove = (Id) => {
        const f = new FolderAPI();
        f.deleteFolders(Id)
        .then(response => {
            f.getFoldersByPage(pageIndex,pageSize)
            .then((response) => {
                setFolder(response.data);
            });
            f.countFolders()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });    
        });
    }

    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('FoldersName', folderName);
        formData.append('RootId', folderId);

        const f = new FolderAPI();
        f.addFolders(formData)
        .then(res => {
            setOpenAddFolder(false);
            setOpenFolder(true);      
            f.getFoldersByPage(pageIndex,pageSize).then((response) => {
                setFolder(response.data);
            });
        });
    };

    const getValueFolderName = (event) => {
        setFolderName(event.target.value);
    };

    const handleShowListFolder = (event) => {
        if(isOpenListFolder) {
            setIsOpenListFolder(!isOpenListFolder);
            setListDropDown([]);
        } else {
            setIsOpenListFolder(!isOpenListFolder);
            const folder = new FolderAPI();
            folder.getListFolders()
            .then((res1) => {
                if(res1.status === 200){
                    const getParentFolderName = (folderId) => {
                        const folder = res1.data.find((f) => f.id === folderId);
                        if(folderId === 0){
                            return "Mặc định";
                        } else {
                            if (folder) {
                                const parentFolder = res1.data.find((f) => f.id === folder.id);
                                if (parentFolder) {
                                    return parentFolder.foldersName;
                                }
                            }
                        }
                        return null;
                    };
                    setListDropDown(
                        res1.data.map((folder) => ({
                            id: folder.id,
                            foldersName: folder.foldersName,
                            rootId: folder.rootId,
                            del: folder.del,
                            level: folder.level,
                            rootName: getParentFolderName(folder.rootId)
                        }))
                    );
                } else {
                    setMessage(res1.detail);
                    setTypeToast('dangerous');
                    setShowToast(true);
                }
            })
        }
    };

    const handleClickItemFolder = (rootId, rootName) => {
        setParentName(rootName);
        setParentId(rootId);
    };

    const DefaultPage = () => {
        setIsOpenListFolder(false);
        setListDropDown([]);
        setParentName('');
        setParentId('');
        setIdEdit(0);
    };

    const handleEdit = (id) => {
        const formData = new FormData();
        formData.append('Id', id);
        formData.append('FolderName', folderName);
        formData.append('RootId', parentId);

        const folder = new FolderAPI();
        folder.updateFolders(formData)
        .then((res) => {
            if(res.status === 200){
                folder.getFoldersByPage(pageIndex,pageSize)
                .then((res1) => {
                    if(res1.status === 200){
                        setFolder(res1.data);
                        setMessage('Cập nhật thành công!');
                        setTypeToast('success');
                        setShowToast(true);
                        DefaultPage();
                    } else {
                        setMessage(res1.detail);
                        setTypeToast('dangerous');
                        setShowToast(true);
                    }
                })
            } else {
                setMessage(res.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        })
    };

    const closeEdit = () => {
        DefaultPage();
    };

    return(<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {
            openFolder && (
                <>
                    <div className={css('background-form')}>
                    </div>
                    <div className={css('form-folder')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>DANH SÁCH THƯ MỤC</p>
                            <FontAwesomeIcon onClick={handleCloseForm} className={css('remove')} icon={faRemove}/>
                        </div>
                        <div className={css('group-search')}>
                            <input className={css('input-search')} placeholder='Tìm kiếm thư mục' onChange={handleValueSearch} value={valueSearch} />
                            <label className={css('label')}>Thêm thư mục</label>
                            <button className={css('add')} onClick={handleOpenAddForm}>
                                <FontAwesomeIcon icon={faAdd}/> Thêm thư mục
                            </button>
                        </div>
                        <div className={css('form-table')}>
                            <table className={css('table')}>
                                <thead className={css('thead')}>
                                    <tr>
                                        <th className={css('th-stt')}>STT</th>
                                        <th className={css('th-ten')}>Tên thư mục</th>
                                        <th className={css('th-ha')}>Thuộc thư mục</th>
                                        <th className={css('th-th')}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody className={css('tbody')}>
                                    {dataFolder.map((res, idx) =>
                                        <tr>
                                            <td>{idx+1}</td>
                                            <td>{res.id===idEdit?<input className={css('input-edit')} value={folderName} onChange={getValueFolderName} />:res.foldersName}</td>
                                            <td>{res.id===idEdit?
                                                <div className={css('group-edit-folder')}>
                                                    <div className={css('group-dropdown')}>
                                                        <input className={css('input-edit')} value={parentName}/>
                                                        {isOpenListFolder && (
                                                            <div className={css('popup-list-file')}>
                                                                {res.rootId !== 0 && (<p onClick={() => handleClickItemFolder(0, 'Mặc định')} style={{cursor: 'default'}}>Mặc định</p>)}
                                                                {listDropdown && listDropdown.map((res1) => res1.id!==res.rootId && (<p onClick={() => handleClickItemFolder(res1.id, res1.foldersName)} style={{cursor: 'default'}}>{res1.level===1?"|--|--|"+res1.foldersName+"|--|--|"
                                                                            :res1.level===2?"|--|--|--|"+res1.foldersName+"|--|--|--|"
                                                                            :"|--|"+res1.foldersName+"|--|"}</p>))}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <button style={{height: '1rem', marginTop: '0.2rem'}} onClick={(e) => handleShowListFolder(e)}><FontAwesomeIcon className={css('btn-down')} icon={faChevronDown}/></button>
                                                </div>
                                            :res.rootName}</td>
                                            {res.id === idEdit? (
                                                <td className={css('action')}>
                                                    <FontAwesomeIcon onClick={() => handleEdit(res.id)} className={css('btn-remove')} icon={faCheck} style={{color: 'green', paddingRight: '1rem'}}/>
                                                    <FontAwesomeIcon onClick={() => closeEdit()} className={css('btn-remove')} icon={faBan} style={{color: 'red'}}/>
                                                </td>
                                            ):(
                                                <td>
                                                    <FontAwesomeIcon onClick={() => handleOpenEdit(res.id)} className={css('btn-remove')} icon={faEdit} style={{marginRight: '1rem'}}/>
                                                    <FontAwesomeIcon onClick={() => handleRemove(res.id)} className={css('btn-remove')} icon={faTrash}/>
                                                </td>
                                                
                                            )}
                                        </tr>
                                    )}
                                </tbody>  
                            </table>
                        </div>
                        <Pagination  currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                    </div>
                </>
            )
        }
        {
            openAddFolder && (
               <>
                    <div className={css('background-form')}>
                    </div>
                    <div className={css('form-folder')}>
                        <div className={css('background-title')}>
                            <p className={css('title')}>THÊM THƯ MỤC</p>
                            <FontAwesomeIcon onClick={handleCloseAddForm} className={css('remove')} icon={faRemove}/>
                        </div>
                        <div className={css('form-add')}>
                            <div className={css('item')}>
                                <div className={css('item-title')}>Tên thư mục</div>
                                <input type='input' className={css('item-input')} value={folderName} onChange={getValueFolderName} autoComplete="off" autoCorrect="off"/>
                            </div>
                            <div className={css('item')}>
                                <div className={css('item-title')}>Thuộc danh mục</div>
                                <input className={css('item-input-dropdown')} type='input' value={foldersName} onClick={() => setIsDropdown(true)} autoComplete="off" autoCorrect="off"/>    
                                <FontAwesomeIcon className={css('icon')} icon={faChevronDown} onClick={handleDropdown}/>
                            </div>
                            {isDropdown&&(
                                <div className={css('group-select')}>
                                    <div onClick={() => handleFolderIdChange({id: 0, foldersName: "Mặc định"})}>Mặc định</div>
                                    {folderTree.map((item, idx) => (<div key={idx} onClick={() => handleFolderIdChange(item)}>{
                                    item.level===1?"|--|--|"+item.foldersName+"|--|--|"
                                    :item.level===2?"|--|--|--|"+item.foldersName+"|--|--|--|"
                                    :"|--|"+item.foldersName+"|--|"
                                    }</div>))}
                                </div>
                            )}
                        </div>
                        <div className={css('group-submit')}>
                            <button onClick={handleSubmit} className={css('button','submit')}>
                                Xác nhận
                            </button>
                            <button className={css('button','cancel')} onClick={handleCloseAddForm}>
                                Hủy
                            </button>
                        </div>
                    </div>
               </>
            )   
        }   
    </>);
}

export default Folder;