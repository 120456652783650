import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/media/';
class Media {
    // uploadMedia({formData, progress}){
    //   return axios.post(path+'upload-media',formData,{
    //     headers: {
    //       'Content-Type': 'multipart/form-data'
    //     },
    //     onUploadProgress: (progressEvent) => {
    //       const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
    //       progress(percentComplete);
    //     },
    //   }).then(response=> response.data)
    //   .catch(error => error.response.data);
    // };

    uploadMedia(formData){
      return axios.post(path+'upload-media',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response=> response.data)
      .catch(error => error.response.data);
    };

    getMediaImageByPosition(formData){
      return axios.get(path+'get-media-image-by-position',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => response.data)
      .catch(error => error.response.data);
    };

    getMediaVideoByPosition(formData){
      return axios.get(path+'get-media-video-by-position',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => response.data)
      .catch(error => error.response.data);
    };

    getPathMediaImageByPosition(formData){
      return axios.post(path+'get-path-media-image-by-position',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => response.data)
      .catch(error => error.response.data);
    };

    getPathMediaVideoByPosition(formData){
      return axios.post(path+'get-path-media-video-by-position',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => response.data)
      .catch(error => error.response.data);
    };
}

export default Media;