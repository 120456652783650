import styles from "./Section8.module.scss";
import className from "classnames/bind";

const css = className.bind(styles);

function Section8(){
    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/tools.png"/>
            </div>    
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>Tài liệu công cụ giải pháp</div>
            <p>Đơn vị tư vấn đã xây dựng bộ công cụ nhằm hỗ trợ cho việc quyết định giải 
                pháp bảo vệ bờ thích hợp với đặc điểm, điều kiện của khu vực cần đầu tư xây dựng công trình. 
                Bộ công cụ này được thiết lập như cây quyết định “decision tree”, đề xuất quy trình, trình tự 
                thực hiện hỗ trợ cho người ra quyết định có thể lựa chọn giải pháp tiềm năng. Đồng thời phương 
                pháp đánh giá đa tiêu chí (MCA) có thể đảm bảo tổng hòa các yếu tố kỹ thuật, kinh tế, xã hội và 
                môi trường để đưa ra giải pháp lựa chọn đáp ứng được yêu cầu về phát triển bền vững.</p>
            <p>Hướng dẫn này là công cụ hữu hiệu giúp cho các cán bộ địa phương bước đầu định hình được giải 
                pháp công trình nhằm ổn định bờ sông đảm bảo phát triển bền vững. Công cụ được xây dựng dự trên 
                nền tảng của ứng dụng Microsoft Excel, thân thiện với người dùng.</p>
            <a href="https://store.satlomekong.vn/documents/Decision_support_tool_CPO-11.xlsm" download>
                <div className={css('file')}>
                    <img className={css('icon-excel')} src="/Assets/Images/icon_excel.png"/>
                    <p style={{color: '#2739DA'}}><u>Decision support tool CPO-11.xlsx</u></p>
                </div>
            </a>
            <a href="https://store.satlomekong.vn/documents/huong_dan_su_dung.pdf" download>
                <div className={css('file')}>
                    <img className={css('icon-excel')} src="/Assets/Images/icon_pdf.png"/>
                    <p style={{color: '#2739DA'}}><u>Hướng dẫn sử dụng</u></p>
                </div>
            </a>
        </div>
    </div>)
}

export default Section8;