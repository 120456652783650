import React, { useEffect, useRef, useState } from 'react';
import className from 'classnames/bind';
import styles from './Loading.module.scss';

const css = className.bind(styles);

function Loading({isOpen=false}){
    return(<>{isOpen && (
        <div className={css('background')}>
            <div className={css('black')}></div>
            <img className={css('image')} src='/Assets/Images/loading.gif'/>
            <p className={css('text')}>Loading...</p>
        </div>
    )}</>);
};

export default Loading;