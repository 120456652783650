import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import styles from './CrossSection.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faUpload } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import FileCrossAPI from '~/Api/FileCross';
import CrossSectionAPI from '~/Api/CrossSection';
import Toast from '~/Components/Controls/Toast';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

function CrossSection({openCrossSection, closeCrossSection, openUpload}) {
    const [isOpenCross, setIsOpenCross] = useState(false);
    const [isOpenInfoCross, setIsOpenInfoCross] = useState(false);
    const [listFileCross, setListFileCross] = useState([]);
    const [listCrossSection, setListCrossSection] = useState([]);
    const [location, setLocation] = useState('');
    const [year, setYear] = useState('');
    const [keyNumber, setKeyNumber] = useState('');
    const [startPosition, setStartPosition] = useState('');
    const [lastPosition, setLastPosition] = useState('');
    const [time, setTime] = useState('');
    const [file, setFile] = useState();
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const pageSize = 10;
    const [pageIndex, setPageIndex] = useState(1);
    const [totalItems, setTotalItems] = useState(1);
    const [valueSearch, setValueSearch] = useState('');
    const [isOpenUpload, setIsOpenUpload] = useState(false);
    
    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleOpenCross = () => {
        setIsOpenCross(!isOpenCross);
        setValueSearch('');
        const fileCrossAPI = new FileCrossAPI();
        fileCrossAPI.getFileCrossByPage(pageIndex, pageSize)
        .then((respon) => {
            if(respon.status === 200){
                setListFileCross(respon.data);
            }
        });

        fileCrossAPI.countFileCross()
        .then(response => {
            if(response.status == 200){
                setTotalItems(response.data);
            }
        });
        if(isOpenCross){
            closeCrossSection(false);
        }
    };

    useEffect(() => {
        if(openCrossSection){
            handleOpenCross();
        }
    },[openCrossSection]);

    const handleOpenCrossInfo = (id) => {
        setIsOpenCross(!isOpenCross);
        setIsOpenInfoCross(!isOpenInfoCross);
        const crossSection = new CrossSectionAPI();
        crossSection.getCrossSectionByFileCrossId(id)
        .then((respon) => {
            if(respon.status === 200){
                setListCrossSection(respon.data);
            }
        });
        const fileCrossAPI = new FileCrossAPI();
        fileCrossAPI.getFileCrossById(id)
        .then((respon) => {
            if(respon.status === 200){
                let object = respon.data;
                setLocation(object.location);
                setYear(object.year);
                setKeyNumber(object.keyNumber);
                setStartPosition(object.startPosition);
                setLastPosition(object.lastPosition);
                setTime(object.time);
            } else {

            }
        });
    };

    const handleGetFile = (event) => {
        setFile(event.target.files[0]);
    };

    const getValueLocation = (event) => {
        setLocation(event.target.value);
    };

    const getValueYear = (event) => {
        setYear(event.target.value);
    };

    const getValueKeyNumber = (event) => {
        setKeyNumber(event.target.value);
    };
    
    const getValueStartPosition = (event) => {
        setStartPosition(event.target.value);
    };

    const getValueLastPosition = (event) => {
        setLastPosition(event.target.value);
    };

    const handleSaveCross = () => {
        const formData = new FormData();
        formData.append('Location',location);
        formData.append('Year',year);   
        formData.append('KeyNumber',keyNumber);
        formData.append('StartPosition',startPosition);
        formData.append('LastPosition',lastPosition);
        formData.append('dataFile',file);
        const fileCrossAPI = new FileCrossAPI();
        fileCrossAPI.uploadFileCross(formData)
        .then((respon) => {
            if(respon === 200){
                setMessage('Upload file thành công.');
                setTypeToast('success');
                setShowToast(true);
                fileCrossAPI.getFileCrossByPage(pageIndex, pageSize)
                .then(response => {
                    if(response.status === 200){
                        setListFileCross(response.data);
                    }
                });
                fileCrossAPI.countFileCross()
                .then(response => {
                    if(response.status === 200){
                        setTotalItems(response.data);
                    }
                });
                handleOpenUpload();
                defaultValue();
            } else {
                setMessage(respon.title);
                setTypeToast('dangerous');
                setShowToast(false);
            }
        })
    };

    const defaultValue = () => {
        setLocation('');
        setYear('');
        setKeyNumber('');
        setStartPosition('');
        setLastPosition('');
        setFile();
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    const handleOpenUpload = () => {
        setIsOpenCross(!isOpenCross);
        setIsOpenUpload(!isOpenUpload);
    };

    const handleGetValueSearch = (event) => {
        setValueSearch(event.target.value);;
        const fileCrossAPI = new FileCrossAPI();
        const search = event.target.value.replaceAll('/','');
        if(search === ''){
            fileCrossAPI.getFileCrossByPage(1, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListFileCross(response.data);
                }
            });
            fileCrossAPI.countFileCross()
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            })
        } else {
            fileCrossAPI.findFileCross(search, 1, pageSize)
            .then(response => {
                if(response.status === 200){
                    setListFileCross(response.data);
                }
            });
            fileCrossAPI.countFindFileCross(search)
            .then(response => {
                if(response.status === 200){
                    setTotalItems(response.data);
                }
            });
        }
    };

    return(<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {/* <button className={css('btn-cross')} onClick={handleOpenCross}>
            <FontAwesomeIcon icon={faChartLine}/> Mặt cắt
        </button> */}
        {isOpenCross && (
            <>
                <div className={css('background-form')}></div>
                <div className={css('form-cross-section')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>Danh sách mặt cắt</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenCross}/>
                    </div>
                    <div className={css('list-cross-section')}>
                        <div className={css('group-search')}>
                            <input className={css('input-search')} placeholder='Tìm kiếm' onChange={handleGetValueSearch} value={valueSearch}/>
                            {openUpload && (
                                <>
                                    <label className={css('label')}>Upload mặt cắt</label>
                                    <button className={css('btn-upload-file')} onClick={handleOpenUpload}><FontAwesomeIcon icon={faUpload}/> Upload file</button>
                                </>
                            )}
                        </div>
                        <table className={css('table')}>
                            <thead className={css('thead')}>
                                <tr>
                                    <th>STT</th>
                                    <th>Địa danh</th>
                                    <th>Số hiệu</th>
                                    <th>Điểm đầu</th>
                                    <th>Điểm cuối</th>
                                    <th>Thời gian khảo sát</th>
                                    <th>Chức năng</th>
                                </tr>
                            </thead>
                            <tbody className={css('tbody')}>
                                {listFileCross && listFileCross.map((res, idx) => 
                                    <tr>
                                        <td>{idx+1}</td>
                                        <td>{res.location}</td>
                                        <td>{res.keyNumber}</td>
                                        <td>{res.startPosition}</td>
                                        <td>{res.lastPosition}</td>
                                        <td>{res.time}</td>
                                        <td><button onClick={() => handleOpenCrossInfo(res.id)}>Hiển thị mặt cắt</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                </div>
            </>
        )}
        {isOpenInfoCross && (
            <>
                <div className={css('background-form')}></div>
                <div className={css('form-cross-section-info')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>Thông tin mặt cắt</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenCrossInfo}/>
                    </div>
                    <div className={css('list-info-cross-section')}>
                        <div className={css('info')}>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Năm khảo sát</p>
                                <input className={css('info-item-input')} value={year}/>
                            </div>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Địa danh</p>
                                <input className={css('info-item-input')} value={location}/>
                            </div>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Số hiệu</p>
                                <input className={css('info-item-input')} value={keyNumber}/>
                            </div>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Thời gian cập nhật</p>
                                <input className={css('info-item-input')} value={time}/>
                            </div>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Vị trí điểm đầu</p>
                                <input className={css('info-item-input')} value={startPosition}/>
                            </div>
                            <div className={css('info-item')}>
                                <p className={css('info-item-title')}>Vị trí điểm cuối</p>
                                <input className={css('info-item-input')} value={lastPosition}/>
                            </div>  
                        </div>
                        <div>
                            <table className={css('table')}>
                                <thead className={css('thead')}>
                                    <tr>
                                        <th>Khoảng cách(m)</th>
                                        <th>Cao trình(m)</th>
                                    </tr>
                                </thead>
                                <tbody className={css('tbody')}>
                                    {listCrossSection && listCrossSection.map((r) => 
                                        <tr>
                                            <td>{r.width}</td>
                                            <td>{r.depth}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <LineChart width={500} height={300} data={listCrossSection}>
                                <Line
                                    type="monotone"
                                    dataKey="depth"
                                    stroke="#8884d8"
                                    strokeWidth={2}
                                />
                                <CartesianGrid strokeDasharray="3 3" />
                                <Tooltip allowEscapeViewBox={{AllowInDimension: {x:false}}} />
                                <XAxis dataKey="width" />
                                <YAxis />
                            </LineChart>
                        </div>
                    </div>
                </div>
            </>
        )}
        {isOpenUpload && (
            <>
                <div className={css('form-upload')}>
                    <div className={css('background-title')}>
                        <p className={css('title')}>Upload mặt cắt</p>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpenUpload}/>
                    </div>
                    <div className={css('upload-cross-section')}>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Năm khảo sát</p>
                            <input className={css('info-item-input')} value={year} onChange={getValueYear}/>
                        </div>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Địa danh</p>
                            <input className={css('info-item-input')} value={location} onChange={getValueLocation}/>
                        </div>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Số hiệu</p>
                            <input className={css('info-item-input')} value={keyNumber} onChange={getValueKeyNumber}/>
                        </div>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Vị trí điểm đầu</p>
                            <input className={css('info-item-input')} value={startPosition} onChange={getValueStartPosition}/>
                        </div>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Vị trí điểm cuối</p>
                            <input className={css('info-item-input')} value={lastPosition} onChange={getValueLastPosition}/>
                        </div>
                        <div className={css('info-item')}>
                            <p className={css('upload-title')}>Thêm file</p>
                            <input type='file' onChange={handleGetFile}/>
                        </div>
                        <div className={css('info-item')}>
                            <button className={css('btn-success')} onClick={handleSaveCross}>Lưu</button>
                            <button className={css('btn-cancel')} onClick={handleOpenUpload}>Hủy</button>
                        </div>
                    </div>
                </div>
            </>
        )}
    </>);
}

export default CrossSection;