import { appConfig } from '~/Config/AppConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/geojson/';

class Geojson{
    uploadShapeFile(formData){
        return axios.post(path+'upload-shape-file',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateColorById(formData){
        return axios.put(path+'update-color-by-id/',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateIconById(formData){
        return axios.put(path+'update-icon-by-id/',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateToolById(formData){
        return axios.put(path+'update-tool-by-id/',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    getGeojsonById(Id){
        return axios.get(path+`get-geojson-by-id/${Id}`)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getGeojsonByFileId(Id){
        return axios.get(path+`get-geojson-by-file-id/${Id}`)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateToolByFileId(formData){
        return axios.put(path+'update-tool-by-file-id/',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };
}
export default Geojson