import styles from "./Section6.module.scss";
import className from "classnames/bind";

const css = className.bind(styles);

function Section6(){
    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/flag.png"/>
            </div>
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>KẾT LUẬN</div>
            <div className={css('heading-2')}>1. Phân tích các xu thế và nguyên nhân của xói lở bờ sông và phân loại</div>
            <p>-  Đánh giá và xác định các thay đổi bờ sông trong hai thập kỷ qua, bằng ảnh vệ tinh cho thấy sạt lở ở sông Tiền lớn hơn 
                ở sông Hậu, mức độ xói lở giảm dần từ thượng nguồn về hạ nguồn và có xu thế giảm dần theo thời gian, 
                do một số khu vực xói lở mạnh đã được đầu tư xây dựng kè bảo vệ bờ.</p>
            <p>-  Hiện tại, ĐBSCL có 96 điểm sạt lở (phân theo Quyết định số 01/2011/QĐ-TTg) 
                trong đó có 17 vị trí đặc biệt nguy hiểm (dài 35,8 km), 34 vị trí nguy hiểm (dài 56,9 km) và 
                42 vị trí sạt lở bình thường (dài 87,6 km). Phân loại theo hình thái sông, sạt lở xảy ra tại 
                các khu vực sông cong (24 điểm - 44,4 km), sông co hẹp, nhập lưu (36 điểm - 67,6 km) và phân lưu/đầu cù lao (38 điểm -69,4 km).</p>
            <p>-  Nguyên nhân chính gây xói lở và làm gia tăng xói lở bờ sông là các tác động từ các công trình thượng nguồn 
                làm giảm lượng bùn cát về ĐBSCL và KTC quá mức. Các nguyên nhân khác tác động khác góp phần gia 
                tăng sạt lở, như xây dựng đê/bờ bao làm tập trung dòng chảy vào lòng chính, xây dựng cơ sở hạ tầng làm 
                tăng tải trọng ven bờ, nuôi cá bè làm gia tăng dòng chảy, gia tăng giao thông thủy tạo ra sóng nhiều hơn.</p>
            <div className={css('heading-2')}>2. Thiết lập hành lang an toàn ven sông và tuyến chỉnh trị sông</div>
            <p>- Từ tổng quan phương pháp xác định hành lang an toàn ven sông trên thế giới và trong nước, 
                tư vấn đã đề xuất xác định theo công thức của Lê Mạnh Hùng và nnk (2004)[21] (B<sub>hlat</sub> ≥15 m khi h ≤10m; 
                B<sub>hlat</sub> ≥20 m khi h =10 m ÷15 m; B<sub>hlat</sub> ≥30 m khi h ≥15m) và có gia tăng hệ số là 3, 2 và 1 khi 
                sông đi qua thành phố, thị xã và thị trấn tương ứng. Hành lang này là cơ sở để phục vụ quy hoạch 
                phát triển các ngành có liên quan (giảm thiểu di dời/đền bù do phát triển không phù hợp với quy hoạch).</p>
            <p>- Thông qua tính toán diễn biến hình thái sông theo 8 KB khác nhau, tư vấn đã lựa chọn được KB 
                phù hợp nhất với những tác động và thay đổi có thể xảy ra trong tương lai, là KB7 
                (bao gồm năm khí hậu điển hình, phát triển thượng nguồn, khai thác cát và BĐKH-NBD). Sử dụng dữ liệu 
                của KB7, tư vấn đã tính toán diễn biến lòng dẫn đến năm 2030 cho toàn bộ sông Tiền và sông Hậu, dự báo sạt 
                lở bờ sông, bản đồ cảnh báo di dời dân cư đến năm 2030, làm cơ sở để lập tuyến chỉnh trị và công trình bảo vệ bờ sông.</p>
            <p>- Tuyến chỉnh trị sông đã thiết lập đảm bảo hình thái sông ổn định lâu dài 
                (tầm nhìn đến năm 2050 dưới tác động của các điều kiện biên thay đổi) và mang lại lợi ích tổng hợp cho tất cả các 
                ngành kinh tế có liên quan đến khai thác dòng sông, đảm bảo phân chia lưu lượng hợp lý giữa sông Tiền, sông Hậu 
                và các nhánh sông, đồng thời cũng để dành một khoảng không gian cho lòng sông tự điều chỉnh và phục hồi hệ sinh thái 
                ven sông từ kinh nghiệm của các nước phát triển. Tuyến chỉnh trị đã khống chế thế sông tại các khu vực có công trình 
                đã xây dựng hoặc đã quy hoạch (kè, cầu, bến cảng…, các khu vực đô thị, khu công nghiệp…).</p>
            <div className={css('heading-2')}>3. Đánh giá những ưu nhược điểm của các công trình bảo vệ bờ sông đã được sử dụng ở ĐBSCL và đề xuất giải pháp khả thi</div>
            <p>Từ kinh nghiệm ở ĐBSCL, công trình bảo vệ bờ “trực tiếp” (kè bờ) là dạng công trình khả thi 
                nhất được áp dụng chủ yếu ở ĐBSCL. Kết cấu của các công trình này có thể áp dụng phù hợp 
                cho các vùng khác nhau (dạng đứng, dạng nghiêng và kết hợp). Giải pháp nạo vét kết hợp 
                khai thác cát hợp lý để kết hợp phát triển kinh tế, và điều chỉnh lưu lượng giữa các nhánh sông nhằm 
                giảm thiểu xói lở cho các khu vực quan tâm là các giải pháp mang lại lợi ích thiết thực, cần được ưu 
                tiên áp dụng, nhưng chưa xác định được (chiều rộng, dài, sâu) và tỷ lệ phân lưu phù hợp …đã được xác định 
                cụ thể trong dự án này thông qua mô phỏng các kịch bản nạo vét/khai thác cát khác nhau, đã được lựa 
                chọn từ phương án gây xói-bồi nhỏ nhất. Từ đó, tỷ lệ phân chia lưu lượng hợp lý cho các nhánh sông cũng được xác định.</p>
            <p>Vì vốn đầu tư công trình kè khá lớn, tư vấn đã phân loại ưu tiên và thực hiện theo thời gian với 3 loại ưu tiên 1, 2 
                và 3 dựa trên phương pháp đánh giá “đa tiêu chí” (đánh giá theo thang điểm). Các tiêu chí liên quan đến mức độ 
                quan trọng của cơ sở hạ tầng (sử dụng đất), mức độ xói sâu của lòng dẫn và chiều sâu lòng dẫn sát chân bờ 
                (dự báo đến năm 2030), tính liền mạch và trơn thuận của tuyến chỉnh trị.</p>
            <p>Dự án đã xây dựng được công cụ hỗ trợ quyết định (Decision support tools - DST) để hỗ trợ ra quyết định dưới dạng 
                “cây quyết định”(decision tree)” để xác định các giải pháp khả thi dựa trên các thông tin đã có và đánh giá đa 
                tiêu chí (MCA). Đồng thời, dự án cũng đã xây dựng “Hệ thống cơ sở dữ liệu Web-GIS” cho phép hiển thị các lớp dữ 
                liệu bản đồ khác nhau (bao gồm các khu vực nguy cơ sạt lở, ranh giới hành lang, cơ sở hạ tầng …) cho phép người 
                dùng tham khảo các giải pháp khả thi chỉnh trị sông, phòng chống sạt lở và  xây dựng “Hướng dẫn sử dụng cơ sở dữ 
                liệu cho hệ thống Web-GIS” tới các bên liên quan của dự án.</p>
            <div className={css('heading-2')}>4. Khuyến nghị</div>
            <p>- Chiều rộng “hành lang” bảo vệ sông này (B<sub>hlat</sub>) cần được cơ quan trung ương thống nhất với các địa phương để ban 
                hành và được quản lý chặt chẽ, không cho xây cất nhà cửa, cơ sở hạ tầng tăng thêm kể từ khi tuyến chỉnh trị được 
                “chấp thuận”. Hành lang này chỉ cho phép sử dụng để làm công viên, trồng cây xanh và các dịch vụ công cộng khác. 
                Bản đồ dự báo sạt lở và di dời dân cư đến năm 2030 cần được các địa phương triển khai, giám sát và cập nhật, điều 
                chỉnh khi có điều kiện (cập nhật khảo sát, tính toán bổ sung…)</p>
            <p>- Kế hoạch/quy hoạch “chỉnh trị sông” cần được thống nhất từ trung ương đến địa phương, phải được cập nhật, điều 
                chỉnh khi cần thiết, để quản lý đảm bảo không mâu thuẫn giữa các công trình, giữa các tỉnh có liên quan. Quy 
                hoạch chỉnh trị sông Tiền và sông Hậu cũng cần phải được cập nhật, điều chỉnh dưới các tác động có thể xảy ra 
                chưa xem xét được trong dự án này, chẳng hạn như thay đổi sử dụng nước ở phía Campuchia (kênh Funan Techno, Biển Hồ…), 
                dẫn nước từ sông Hậu cung cấp cho các hệ thống thủy lợi ở bán đảo Cà Mau, Cái Lớn – Cái Bé v.v…</p>
            <p>- Các giải pháp công trình chỉnh trị “gián tiếp” như các mỏ hàn cứng, mỏ hàn cọc, đập ngầm…để điều chỉnh dòng chảy và 
                tỷ lệ phân lưu cần được nghiên cứu đánh giá tác động và hiệu quả của chúng (bằng mô hình toán hoặc mô hình vật lý) 
                trước khi áp dụng. Cần có chương trình nghiên cứu cụ thể để áp dụng các giải pháp công trình chỉnh trị dựa vào tự nhiên 
                (NbS) bao gồm cả các giải pháp phi công trình như quản lý xây dựng công trình, quản lý và khai thác lòng sông (khai thác 
                cát, giao thông thủy), quản lý khai thác và phát triển hành lang ven sông (bao gồm dự báo, cảnh báo sạt lở theo thời gian).</p>
            <p>- Để nâng cao hiệu quả của dự án và tăng cường năng lực quản lý bờ sông vùng ĐBSCL, toàn bộ sông Tiền, sông Hậu (và các kênh 
                rạch chính) cần được khảo sát đồng bộ, có hệ thống mốc định vị rõ ràng (theo Km) từ biên giới đến biển để dễ theo dõi, 
                quản lý và phục vụ cho tất cả các ngành kinh tế có liên quan.</p>
        </div>
    </div>);
}

export default Section6;