import styles from "./Section4.module.scss";
import className from "classnames/bind";

const css = className.bind(styles);

function Section4(){
    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/disadvantage.png"/>
            </div>
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>ƯU NHƯỢC ĐIỂM CÁC CÔNG TRÌNH BẢO VỆ BỜ</div>
            <p>Để từng bước hạn chế tình trạng sạt lở bờ sông, uy hiếp cuộc sống của người dân sống hai bên bờ 
                sông, ngăn cản tình trạng sụp đổ nhà cửa, cơ sở hạ tầng ... ven sông đặc biệt là tại các đô thị lớn, 
                nhiều các công trình, công trình bảo vệ bờ đã được thực hiện trên hệ thống sông ở ĐBSCL. Đầu tiên 
                là công trình bảo vệ bờ sông Cổ Chiên khu vực bờ hữu thuộc thành phố Vĩnh Long, tiếp đến là bờ hữu 
                sông Tiền khu vực thị xã Sa Đéc (bảo vệ chợ Sa Đéc bằng đập khóa rạch Nhà Thượng và bảo vệ bờ sông Tiền ...). 
                Bên cạnh đó những công trình phức tạp, kết hợp nhiều giải pháp công trình để đẩy dòng chảy ra giữa dòng bảo 
                vệ thị trấn Tân Châu, nay là thị xã Tân Châu tỉnh An Giang ... và còn nhiều công trình bảo vệ bờ đã được xây dựng trong hơn 35 năm qua. </p>
            <p>Mặc dùng công trình được xây dựng trên nền đất yếu, sông sâu, dòng chảy với vận tốc lớn, xong nhìn chung 
                các công trình đã xây dựng đã đáp ứng đầy đủ vệ mặt kỹ thuật, hoàn thành mục tiêu đã đặt ra, hạn chế tình 
                trạng sạt lở bờ ở những khu vực trọng điểm, chỉnh trang đô thị ở các thành phố, thị xã ven sông. Tuy nhiên, còn tồn tại và khắc phục một số điểm sau:</p>
            <p>- Một số công trình bảo vệ bờ đã xây dựng ở ĐBSCL chưa dựa trên cơ sở quy hoạch chỉnh trị của sông hay cho đoạn sông hoặc xây dựng nhưng 
                không tuân thủ hoàn toàn theo đồ án chỉnh trị chỉ thực hiện được một phần hạng mục. Gần như tình trạng sạt lở xảy ra gây nguy hiểm là tiến hành xây dựng công trình;</p>
            <p>- Từ  kinh nghiệm nghiên cứu, thiết kế, xây dựng công trình kè ở ĐBSCL, công trình bảo vệ bờ “trực tiếp” hay còn gọi 
                là kè bờ là dạng công trình mang lại hiệu quả cao nhất, ít gây tác động nhất đến khu vực và vùng lân cận. 
                Đây cũng là loại công trình được áp dụng chủ yếu ở ĐBSCL. Có một vài công trình có sự cố chủ yếu là do việc tính toán ổn 
                định công trình chưa lường trước được tác động của dòng chảy gây xói lòng dẫn trong quá trình sử dụng/vận hành công trình mà chưa được 
                quan tâm theo dõi/đánh giá, hoặc quá trình dự án kéo dài dẫn đến diễn biến lòng sông bất lợi mà công tác thiết kế chưa đáp ứng kịp, lòng sông 
                bị xói sâu thêm, phạm vi sạt lở đã mở rộng, không đủ kinh phí thực hiện. Một số ít công trình tính toán tác động của tải trọng ngang chưa đầy đủ 
                dẫn đến công trình bị chuyển vị, sụp đổ theo phương ngang.</p>
            <p>- Công trình “gián tiếp”, đó là các loại công trình chỉnh trị tác động vào dòng chảy vẫn còn 
                ít được áp dụng và chưa chứng minh rõ hiệu quả về mặt kinh tế so với các giải pháp khác. Hầu hết các 
                giải pháp đề xuất về chỉnh trị như mỏ hàn, nạo vét, bè cá điều chỉnh dòng chảy chưa được nghiên cứu đánh 
                giá tác động/hiệu quả của chúng (bằng mô hình toán/mô hình vật lý), do đó bố trí không gian quy mô công trình chưa 
                phù hợp, cần tiếp tục được nghiên cứu bổ sung, đặc biệt đối với đoạn sông phân lạch phải xác định được tỷ lệ phân lưu nào 
                là hợp lý để giảm thiểu xói lở cho khu vực đề xuất và vùng lân cận.</p>
            <p>- Giải pháp nạo vét kết hợp khai thác cát, với quy mô và cao độ hợp lý nhằm điều chỉnh lưu lượng giữa các nhánh 
                sông nhằm giảm thiểu xói lở cho các khu vực có hạ tầng phát triển dân cư đông, ổn định thế sông là các giải pháp 
                mang lại lợi ích thiết thực, cần được ưu tiên áp dụng để khơi thông dòng chảy, tận dụng cát nạo vét phát triển kinh tế xã hội.</p>
            <p>- Giải pháp dựa vào tự nhiên (NbS) bao gồm cả các giải pháp phi công trình đã được triển khai thực hiện như: 
                nâng cao nhận thực cộng đồng; cắm mốc gianh giới an toàn cho các khu vực có nguy cơ sạt lở; xây dựng kế hoạch nạo 
                vét lòng sông để đảm bảo tỷ lệ phân lưu; quy định các khu vực không được khai thác cát hay cấm khai thác cát sạt bờ sông 
                với từng đoạn lòng sông theo độ sâu, độ thoải của mái; nghiêm cấm không được xây dựng nhà cửa, công trình lấn chiếm bờ sông, 
                chất tải trọng nặng bên sông …Tuy nhiên, ý chức người dân chưa cao, công tác quản lý còn chồng chéo, xử phạt chưa đủ mức răn đe v.v…, 
                cần phải có những kế hoạch, chương trình cụ thể nhằm giảm thiểu xói lở nghiêm trọng đã, đang và tiếp tục gia tăng dưới tác động của các hoạt 
                động phát triển thượng nguồn, phát triển đồng bằng và BĐKH-NBD trong  tương lai.</p>
        </div>
    </div>);
}

export default Section4;