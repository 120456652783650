import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/cross-section/';

class CrossSection {
    getCrossSectionByFileCrossId(FileCrossId) {
        return axios.get(path+'get-cross-section-by-file-cross-id/'+FileCrossId)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default CrossSection;