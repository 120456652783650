import { appConfig } from '~/Config/AppConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/documents/';
class Documents {
    getDocumentsByPage(pageIndex,pageSize){
        return axios.get(path+'get-documents-by-page/'+`${pageIndex}/${pageSize}`)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    uploadDocuments(formData){
        return axios.post(path+'upload-documents/',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response => response.data)
        .catch(error => error.response.data);
    };

    countDocuments(){
        return axios.get(path+'count-documents/')
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    deleteDocuments(id){
        return axios.put(path+'delete-documents/'+id)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
};

export default Documents;