import React, { useState, useEffect } from 'react';
import styles from './FormSolution.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders, faRemove, faTrash, faAdd } from '@fortawesome/free-solid-svg-icons';
import SolutionAPI from '../../Api/Solution';
import Toast from '../Controls/Toast';

const css = className.bind(styles);

function FormSolution({isOpen, ten, chieudai, close}){
    const [listSolution, setListSolution] = useState([]);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [valueChieuDai, setValueChieuDai] = useState(chieudai?chieudai:0);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleCloseFormSolution = () => {
        close(false);
    };

    const handleChieuDai = (event) => {
        setValueChieuDai(event.target.value);
    };

    useEffect(() => {
        // const information = new Information();
        // information.getInformationByFeatureId(Id)
        // .then((respon) => {
        //     if(respon.status == 200) {
        //         if(respon.data){
        //             setNameProject(respon.data.name);
        //             setLength(respon.data.length);
        //         }
        //     } else {
        //         setShowToast(true);
        //         setMessage(respon.title);
        //         setTypeToast('dangerous');
        //     }
        // });

        const solution = new SolutionAPI();
        solution.getSolution()
        .then((respon) => {
            if(respon.status == 200){
                setListSolution(respon.data);
            } else {
                setShowToast(true);
                setMessage(respon.title);
                setTypeToast('dangerous');
            }
        });
    },[ten]);
    
    return (<>
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {isOpen && (
            <div className={css('form-solution')}>
                <div className={css('background-title')}>
                    <p className={css('title')}>Đề xuất giải pháp</p>
                    <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleCloseFormSolution}/>
                </div>
                <div className={css('list-solution')}>
                    <div className={css('infor')}>
                        <div className={css('item')}>
                            <label className={css('title-project')}>Tên dự án:</label>
                            <p className={css('value-name')}>{ten}</p>
                        </div>
                        <div className={css('item')}>
                            <label className={css('title-project')}>Chiều dài:</label>
                            <p className={css('value-name')}>{valueChieuDai} m</p>
                        </div>
                        <div className={css('item')}>
                            <label className={css('title-project')}>Nhập chiều dài:</label>
                            <input className={css('input-length')} value={valueChieuDai} onChange={(event) => handleChieuDai(event)}/>
                        </div>
                    </div>
                    <table className={css('table')}>
                        <thead className={css('thead')}>
                            <tr>
                                <th>STT</th>
                                {/* <th>Vị trí</th> */}
                                <th>Giải pháp</th>
                                <th>Đơn giá/m</th>
                                <th>Thành tiền</th>
                            </tr>
                        </thead>
                        <tbody className={css('tbody')}>
                            {listSolution.map((res, idx) =>
                                <tr>
                                    <td>{idx+1}</td>
                                    {/* <td>{res.position}</td> */}
                                    <td>{res.constructionType}</td>
                                    <td>{res.unitPrice.toLocaleString()}{',000 VNĐ'}</td>
                                    <td>{(res.unitPrice*Math.floor(valueChieuDai)).toLocaleString()}{',000 VNĐ'}</td>
                                </tr>)}  
                        </tbody>
                    </table>
                </div>
            </div>
        )}
    </>);
}

export default FormSolution;