import React, { useState, useEffect, useRef } from 'react';
import styles from './ListDocuments.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove, faUpload, faSave, faDownload, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import DocumentAPI from '~/Api/Documents';
import Toast from '~/Components/Controls/Toast';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

function ListDocuments ({isOpenList}){
    const [isOpen, setIsOpen] = useState(false);
    const pageSize = 7;
    const [pageIndex, setPageIndex] = useState(1);
    const [dataDocument, setDataDocument] = useState([]);
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [totalItems, setTotalItems] = useState(1);
    
    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    useEffect(() => {
        const documents = new DocumentAPI();
        documents.getDocumentsByPage(pageIndex, pageSize).then(response => {
            if(response.status === 200){
                setDataDocument(response.data);
            }
        });
    },[pageIndex]);

    const handleSave = () => {
        const formData = new FormData();
        formData.append('dataFile', file);
        const document = new DocumentAPI();
        document.uploadDocuments(formData)
        .then((response) => {
            if(response.status == 200) {
                fetchData();
                setMessage('Đã upload thành công.');
                setTypeToast('success');
                setShowToast(true);
                setFile(null);
                setFileName('');     
            } else {
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
    };

    const handleDelete = (Id) => {
        const documents = new DocumentAPI();
        documents.deleteDocuments(Id)
        .then((response) => {
            if (response.status == 200){
                fetchData();
                setMessage('Đã xóa thành công.');
                setTypeToast('success');
                setShowToast(true);
            } else {
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
    };

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleGetFile = (event) => {
        const fileUpload = event.target.files[0];
        setFile(fileUpload);
        setFileName(fileUpload.name);
    };

    const fetchData = () => {
        const documents = new DocumentAPI();
        documents.getDocumentsByPage(1, pageSize).then(response => {
            if(response.status === 200){
                setDataDocument(response.data);
            } else {
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
        documents.countDocuments().then(response => {
            if(response.status == 200){
                setTotalItems(response.data);
            } else {
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
    };

    useEffect(() => {
        if(isOpenList) {
            setIsOpen(true);
            fetchData();
        }
    },[isOpenList]);

    return(
        <>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            {isOpen && (
                <div className={css('form-documents')}>
                    <div className={css('background-title')}>
                        <div className={css('title')}>Danh sách tài liệu</div>
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpen}/>
                    </div>
                    <div className={css('form-body')}>
                        {
                            file && (
                                <div className={css('header')}>
                                    <p className={css('file-name')}>{fileName}</p>
                                    <div className={css('btn-upload')} style={{marginLeft: '0.5rem'}} onClick={handleSave}><FontAwesomeIcon icon={faSave}/> Lưu</div>
                                </div>
                            )
                        }
                        {
                            file==null && (
                                <div className={css('header')}>
                                    <div className={css('btn-upload')} onClick={handleButtonClick}><FontAwesomeIcon icon={faUpload}/> Upload tài liệu</div>
                                    <input 
                                        type='file'
                                        ref={fileInputRef}
                                        style={{ display: 'none' }} 
                                        onChange={handleGetFile}/>
                                </div>
                            )
                        }
                        <div className={css('container')}>
                            <table className={css('table')}>
                                <thead className={css('thead')}>
                                    <tr>
                                        <th className={css('th-stt')} style={{width: '10%'}}>STT</th>
                                        <th className={css('th-name')} style={{width: '70%'}}>Tên</th>
                                        <th className={css('th-action')}>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody className={css('tbody')}>
                                {
                                    dataDocument && dataDocument.map((res, idx) => (
                                        <tr className={css('tr')}>
                                            <td style={{width: '10%', textAlign: 'center'}}>{(pageIndex - 1)*pageSize+idx+1}</td>
                                            <td style={{textAlign: 'start', padding: '0rem 0.3rem', width: '70%'}}>{res.name}</td>
                                            <td style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                                <div className={css('btn-tt')}>
                                                    <a target='_blank' href={res.path}><FontAwesomeIcon icon={faDownload}/></a>
                                                </div>
                                                <div className={css('btn-tt')} onClick={() => handleDelete(res.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                        <Pagination currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
                    </div>
                </div>
            )}
        </>
    );
};

export default ListDocuments;