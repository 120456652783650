import React, { useState } from 'react';
import className from 'classnames/bind';
import styles from './ImageSilde.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';

const css = className.bind(styles);

const ImageSlider = ({urlArr}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === urlArr.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? urlArr.length - 1 : prevSlide - 1));
  };
  return (
    <div className={css("image-slider")}>
      <div className={css("slider")}>  
        {urlArr.map((item,idx)=>idx===currentSlide&&(<div key={idx} className={css("slide")} style={{backgroundImage: `url(${item})`}}></div>))}
      </div>
      <div className={css("dot")}>
        {urlArr.map((item,idx)=>(<FontAwesomeIcon key={idx} className={css("icon-dot")} style={{color: idx===currentSlide?'#5b5b5b':'#eeeeee'}} icon={faCircle}>
        </FontAwesomeIcon>))}
      </div>
      <FontAwesomeIcon className={css("prev-button")} onClick={prevSlide} icon={faChevronLeft}>
      </FontAwesomeIcon>
      <FontAwesomeIcon className={css("next-button")} onClick={nextSlide} icon={faChevronRight}>
      </FontAwesomeIcon>
    </div>
  );
};

export default ImageSlider;