import React, { useState, useEffect } from 'react';
import styles from "./Section7.module.scss";
import className from "classnames/bind";
import DocumentAPI from '~/Api/Documents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Pagination from '~/Components/Controls/Pagination';

const css = className.bind(styles);

function Section7(){
    const pageSize = 7;
    const [pageIndex, setPageIndex] = useState(1);
    const [dataDocument, setDataDocument] = useState([]);
    const [totalItems, setTotalItems] = useState(1);
    
    const fetchData = () => {
        const documents = new DocumentAPI();
        documents.getDocumentsByPage(1, pageSize).then(response => {
            if(response.status === 200){
                setDataDocument(response.data);
            }
        });
        documents.countDocuments().then(response => {
            if(response.status == 200){
                setTotalItems(response.data);
            }
        });
    };

    const handlePageChange = (data) => {
        setPageIndex(data);
    };

    useEffect(() => {
        const documents = new DocumentAPI();
        documents.getDocumentsByPage(pageIndex, pageSize).then(response => {
            if(response.status === 200){
                setDataDocument(response.data);
            }
        });
    },[pageIndex]);

    useEffect(() => {
        fetchData();
    },[]);

    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/download.png"/>
            </div>    
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>DANH SÁCH TÀI LIỆU</div>
            <table className={css('table')}>
                <thead className={css('thead')}>
                    <tr>
                        <th className={css('th-stt')} style={{width: '10%'}}>STT</th>
                        <th className={css('th-name')} style={{width: '70%'}}>Tên</th>
                        <th className={css('th-action')}>Thao tác</th>
                    </tr>
                </thead>
                <tbody className={css('tbody')}>
                {
                    dataDocument && dataDocument.map((res, idx) => (
                        <tr className={css('tr')}>
                            <td style={{width: '10%', textAlign: 'center'}}>{(pageIndex - 1)*pageSize+idx+1}</td>
                            <td style={{textAlign: 'start', padding: '0rem 0.3rem', width: '70%'}}>{res.name}</td>
                            <td style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                                <div className={css('btn-tt')}>
                                    <a target='_blank' href={res.path}><FontAwesomeIcon icon={faDownload}></FontAwesomeIcon></a>
                                </div>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
            <Pagination currentPage={pageIndex} pageSize={pageSize} totalItems={totalItems} onPageChange={handlePageChange}/>
        </div>
    </div>)
}

export default Section7;