import React, { useState, useEffect } from 'react';
import styles from './Note.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import NoteAPI from '~/Api/Note';
import File from '~/Api/File';

const css = className.bind(styles);

function Note({isOpenRegister, isClose}) {
    const [isOpen, setIsOpen] = useState(false);
    const [listNote, setListNote] = useState([]);

    const handleOpen = () => {
        setIsOpen(!isOpen);
        isClose(!isOpen);
    };

    useEffect(() => {
        // const f = new File();
        // f.listFileAndGeoJSON().then((res) => {
        //     if (listNote.length === 0 ){
        //         setListNote(res.data);
        //     }
        // });

        const n = new NoteAPI();
        n.getListNote().then((response) => {
            if (listNote.length === 0) {
                setListNote(response.data);
            }
        });
    }, []);

    useEffect(() => {
        if(isOpenRegister){
            setIsOpen(false);
        }
    },[isOpenRegister]);

    return (
        <div>
            <button className={css('btn-note')} onClick={handleOpen}>
                <FontAwesomeIcon icon={faNoteSticky} /> Chú thích
            </button>
            {isOpen && (
                <div className={css('map-note')}>
                    <p className={css('note-title')}>CHÚ THÍCH</p>
                    <div className={css('group-note')}>
                        {listNote.map((res, idx) => (
                            <div className={css('item-note', idx % 2 === 0 ? '' : 'item-1')}>
                                {res.type===2?<img className={css('img')} src={res.path}/>:<></>}                                
                                {res.type===0?<div className={css('line')} style={{backgroundColor: `${res.colors}`}}></div>:<></>}
                                {res.type===1?
                                    <div className={css('dash')}>
                                        <div className={css('dash-1')} style={{backgroundColor: `${res.colors}`}}></div>
                                        <div className={css('dash-1')} style={{backgroundColor: `${res.colors}`}}></div>
                                        <div className={css('dash-1')} style={{backgroundColor: `${res.colors}`}}></div>
                                </div>:<></>}
                                <p className={css('title')}>{res.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Note;
