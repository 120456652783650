import { appConfig } from '~/Config/AppConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/files/';
class File {
    getFilesAndFolderName(pageIndex,pageSize){
        return axios.get(path+'get-files-and-folder-name/'+`${pageIndex}/${pageSize}`)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFilesByListFolder(listFolder){
        return axios.get(path+'get-files-by-list-folders'+'/'+listFolder)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFilesByFoldersId(folderId){
        return axios.get(path+'get-files-by-folders-id'+'/'+folderId)
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFiles(){
        return axios.get(path+'get-files/')
        .then(response=> response.data)
        .catch(error => error.response.data);
    };

    getFilesById(Id){
        return axios.get(path+'get-files-by-id/'+Id)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    uploadFile(formData) {
        return axios.post(path+'uploadfile',formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    uploadShapeFileToGeoJSON(formdata) {
        return axios.post(path+'upload-shape-file-to-geojson/',formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateRenameFile(formdata) {
        return axios.put(path+'rename-files/',formdata, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response => response.data)
        .catch(error => error.response.data);
    };

    deleteFile(Id) {
        return axios.put(path+'delete-files/'+Id)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    updateFiles(formData) {
        return axios.put(path+'update-files/',formData)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    countFilesAndFolderName(){
        return axios.get(path+'count-file-and-folder-name/')
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    findFilesAndFolderName(keyword, pageIndex, pageSize){
        return axios.get(path+`find-file-and-folder-name/${keyword}/${pageIndex}/${pageSize}`);
    };

    countFindFilesAndFolderName(keyword){
        return axios.get(path+`count-find-file-and-folder-name/${keyword}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getFileGeoToBase64(id){
        return axios.get(path+`get-file-geo-to-base64/`+id)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    listFileAndGeoJSON(){
        return axios.get(path+`list-file-and-geojson/`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default File;