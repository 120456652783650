import React, {useState} from 'react';
import styles from './ChangeMap.module.scss';
import className from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';

const css = className.bind(styles);

function ChangeMap({onDataChange}){
    const [isHovered, setIsHovered] = useState(false);
    const [logo, setLogo] = useState(0);
    const [title, setTitle] = useState(1);

    const listTitle = ['Google Satellite', 'Google Terrain and Standard roadmap', 'OpenStreetMap', 'Google Satellite and Roads', 'Google Roads',
    'Google Terrain and Roads', 'Google Terrain', 'Google Standard Roadmap'];

    const handleOpenGroupChange = () => {
        setIsHovered(!isHovered)
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleChangeMap = (value) => {
        if(value===1) {
            onDataChange('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 2) {
            onDataChange('http://{s}.google.com/vt/lyrs=m,t&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 3) {
            onDataChange('https://a.tile.openstreetmap.org/{z}/{x}/{y}.png');
            setLogo(1);
            setTitle(value);
        } else if (value === 4) {
            onDataChange('http://{s}.google.com/vt/lyrs=s,r&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 5) {
            onDataChange('http://{s}.google.com/vt/lyrs=h&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 6) {
            onDataChange('http://{s}.google.com/vt/lyrs=h,t&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 7) {
            onDataChange('http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        } else if (value === 8) {
            onDataChange('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=vi&gl=vn');
            setLogo(0);
            setTitle(value);
        }
    };

    return (
        <div 
            >
            <div className={css('btn-change-map')}
                onMouseEnter={handleMouseEnter}
                onClick={handleOpenGroupChange}        
            >
                <div className={css('row')}>
                    <img className={css('logo')} src={logo===0?'/Assets/Images/logo_google.png':'/Assets/Images/logo_osm.png'}/>
                    <p className={css('text')}>{listTitle[title-1]}</p>
                </div>
            </div>
            {isHovered && 
                <div className={css('group-change-map')}
                onMouseLeave={handleMouseLeave}
                >
                    <div className={css('group-item')} onClick={() => handleChangeMap(1)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Satellite</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(2)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Terrain and Standard roadmap</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(3)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_osm.png"/>
                        <p className={css('title-item')}>OpenStreetMap</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(4)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Satellite and Roads</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(5)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Roads</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(6)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Terrain and Roads</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(7)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Terrain</p>
                    </div>
                    <div className={css('group-item')} onClick={() => handleChangeMap(8)}>
                        <img className={css('logo-item')} src="/Assets/Images/logo_google.png"/>
                        <p className={css('title-item')}>Google Standard Roadmap</p>
                    </div>
                </div> 
            }
            
        </div>
    );
}

export default ChangeMap;