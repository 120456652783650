import styles from "./Section3.module.scss";
import className from "classnames/bind";

const css = className.bind(styles);

function Section3(){
    return(<div className={css('container')}>
    <div className={css('header')}>
        LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
    </div>
    <div className={css('container-logo')}>
        <div className={css('logo')}>
            <img src="/Assets/Images/Intro/table-content.png"/>
        </div>
    </div>
    <div className={css('content')}>
        <div className={css('heading-1')}>MỤC TIÊU TỔNG THỂ</div>
        <p>Mục tiêu chính của dịch vụ tư vấn này là xác định các biện pháp chỉnh trị sông và các giải pháp quản lý xói lở 
        bờ sông dọc theo sông Tiền và sông Hậu, đảm bảo ổn định ngắn hạn và dài hạn cho sinh kế địa phương, cơ sở hạ tầng 
        ven sông và nâng cao năng lực quản lý bờ sông ở ĐBSCL.</p>
        <p style={{fontWeight: 'bold'}}>Mục tiêu chính của gói thầu</p>
        <p>- Xác định được phương án chỉnh trị các tuyến sông chính thuộc sông Tiền và sông Hậu;</p>
        <p>- Đề xuất được các giải pháp bảo vệ bờ sông bảo đảm ổn định trước mắt và lâu dài đối với dân sinh, cơ sở hạ tầng vùng ven sông;</p>
        <p>- Đề xuất được các giải pháp nâng cao năng lực quản lý bờ sông vùng đồng bằng sông Cửu Long.</p>
        <p style={{fontWeight: 'bold'}}>Mục tiêu cụ thể</p>
        <p>1) Xác định được các nguyên nhân chính gây ra sạt lở bờ sông Tiền và sông Hậu; </p>
        <p>2) Lập được bản đồ hiện trạng, dự báo xác định được các khu vực hoặc vị trí chính có nguy cơ cao xảy ra sạt lở bờ sông;</p>
        <p>3) Xác định được các giải pháp công trình và phi công trình ổn định bờ sông trước mắt và lâu dài;</p>
        <p>4) Xây dựng được hướng dẫn về chỉnh trị sông phục vụ quản lý xói lở bờ sông;</p>
        <p>5) Nâng cao năng lực quản lý rủi ro sạt lở bờ sông vùng ĐBSCL.</p>
    </div>
</div>);
}

export default Section3;