export const appConfig = {
    // APP_NAME: process.env.REACT_APP_NAME,
    APP_NAME: 'Lập phương án chỉnh trị và phòng chống sạt lở bờ sông vùng ĐBSCL',
    GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
    API_TIMEOUT: process.env.SERVER_API_TIMEOUT,
    // API: 'https://api.thuyloitravinh.vn/',
    API: 'https://api.satlomekong.vn/',
    // API: '/call/',
    // API: 'https://localhost:7146/',
    // API: 'http://172.31.99.101:7878/',
    SERVER_API_URL: 'https://localhost:7146/api/v1',
    KEY: 'ho2na321nmjdalpq'
};
