import styles from './Section2.module.scss';
import className from "classnames/bind";

const css = className.bind(styles);

function Section2 (){
    return(<div className={css('container')}>
        <div className={css('header')}>
            LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG
        </div>
        <div className={css('container-logo')}>
            <div className={css('logo')}>
                <img src="/Assets/Images/Intro/river.png"/>
            </div>
        </div>
        <div className={css('content')}>
            <div className={css('heading-1')}>HIỆN TRẠNG SẠT LỞ BỜ SÔNG TIỀN, SÔNG HẬU</div>
            <p>Trong những năm gần đây tình trạng sạt lở bờ sông, xói lòng dẫn trên hệ thống sông Tiền, 
                sông Hậu đang diễn ra hết sức nghiêm trọng, hệ quả là không những làm mất đất sản xuất nông nghiệp, 
                hư hỏng cơ sở hạ tầng, ảnh hưởng và uy hiếp đến đời sống và tính mạng người dân sinh sống dọc hai bến 
                bờ sông mà còn xóa sổ một số cồn, cù lao ở sông Tiền và sông Hậu, biến mất trên bản đồ hành chính.</p>
            <p>Nghiên cứu tài liệu lịch sử, phân tích xử lý ảnh viễn thám, tổng hợp các báo cáo tình hình sạt lở bờ sông 
                hàng năm của các địa phương, đồng thời tổ chức nhiều đợt điều tra, khảo sát thực tế chúng tôi đã thống kê 
                được các vị trí xói lở bờ trên hệ thống sông Tiền, sông Hậu. Kết quả tổng hợp tình hình sạt lở tính đến thời 
                điểm hiện tại, tổng cộng có 96 vị trí sạt lở bờ .</p>
            <p style={{fontWeight: 'bold'}}>Trên hệ thống sông Tiền có tới 59 vị trí sạt lở, sông Hậu chỉ có 37 vị trí sạt lở, 
            sạt lở bờ sông Tiền với quy mô lớn hơn, phức tạp hơn, thường xuyên hơn sạt lở bờ sông Hậu rất nhiều. Điều này đặt ra 
            cho chúng ta một cái nhìn đấy đủ về công tác chỉnh trị ổn định toàn hệ thống sông Cửu Long, nhằm hạn chế sạt lở bờ. Đó 
            là việc nghiên cứu giải pháp điều chỉnh tỷ lệ phân lưu giữa sông Tiền và sông Hậu, thông qua việc xây dựng hệ thống công 
            trình hướng dòng kết hợp nạo vét lòng dẫn tại nút phân lưu Cù Lao Ma, tăng lưu lượng qua sông Vàm Nao, tức là tăng lưu lượng 
            dòng chảy qua sông Hậu. Tuy vậy, vấn đề cần được lưu tâm là khống chế, hạn chế sạt lở bờ sông Vàm Nao, đánh giá vấn đề 
            xâm nhập mặn của toàn hệ thống (vùng các cửa sông) để đảm bảo chất lượng nước mặn, ngọt cho sản xuất nông nghiệp và nuôi trồng thủy sản ở vùng này.</p>
        </div>
    </div>);
}

export default Section2;