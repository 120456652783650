import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API+'api/v1/vai-tro/';
class Role {
    getConcurrencyStampAdmin(){
        return axios.get(path+`get-concurrency-stamp-admin/`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getConcurrencyStampOperator(){
        return axios.get(path+`get-concurrency-stamp-operator/`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getConcurrencyStampAdminAndOperator(){
        return axios.get(path+`get-concurrency-stamp-admin-and-operator/`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getConcurrencyStampByUserId(userId){
        return axios.get(path+`get-concurrency-stamp-by-user-id/${userId}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default Role;