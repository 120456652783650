import React, { useState, useEffect, useRef } from 'react';
import className from 'classnames/bind';
import styles from './Introduction.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Section1 from '~/Components/Section/Section1';
import Section2 from '~/Components/Section/Section2';
import Section3 from '~/Components/Section/Section3';
import Section4 from '~/Components/Section/Section4';
import Section5 from '~/Components/Section/Section5';
import Section6 from '~/Components/Section/Section6';
import Section7 from '~/Components/Section/Section7';
import Section8 from '~/Components/Section/Section8';

const css = className.bind(styles);

function Introduction ({openPage}){
    const [numberSection, setNumberSection] = useState(0);

    const changePage = () => {
        openPage(true);
    };

    const handleClickNumberSection = (number) => {
        setNumberSection(number);
    };

    return (<div className={css('background')}>
        <p className={css('title')}>LẬP PHƯƠNG ÁN CHỈNH TRỊ VÀ PHÒNG CHỐNG SẠT LỞ BỜ SÔNG VÙNG ĐỒNG BẰNG SÔNG CỬU LONG</p>
        <div className={css('group-tools')}>
            <div className={css('title-1')}>Công cụ hỗ trợ ra quyết định</div>
        </div>
        {window.innerWidth > 430 && (
            <div className={css('gird')}>
                <div className={css('gallery')}>
                    <div className={css('child')} onClick={() => handleClickNumberSection(1)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>1. Giới thiệu</p>
                        <img src='/Assets/Images/Intro/countdown.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={changePage}>
                        <img src='/Assets/Images/MDmap_3.png' style={{width: '35vh', height: '35vh'}} alt='Click vào để xem'/>
                        {/* <div style={{textAlign: 'end', width: '130%', fontSize:'1rem'}}><p>Nhấn vào để xem bản đồ</p></div> */}             
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(8)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>8. Công cụ giải pháp</p>
                        <img src='/Assets/Images/Intro/tools.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(2)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>2. Hiện trạng sạt lở bờ <br/> sông Tiền , sông Hậu</p>
                        <img src='/Assets/Images/Intro/river.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(6)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>6. Kết luận <br/> kiến nghị</p>
                        <img src='/Assets/Images/Intro/flag.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(4)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>4. Ưu nhược điểm các công trình bảo vệ bờ</p>
                        <img src='/Assets/Images/Intro/disadvantage.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(5)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>5. Tuyến chỉnh trị, hành lang an toàn</p>
                        <img src='/Assets/Images/Intro/dam.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(7)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>7. Tài liệu</p>
                        <img src='/Assets/Images/Intro/download.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                    <div className={css('child')} onClick={() => handleClickNumberSection(3)}>
                        <p style={{color: 'black', fontSize: '1.8vh'}}>3. Mục tiêu và các nội dung chính</p>
                        <img src='/Assets/Images/Intro/table-content.png' style={{width: '7vh', height: '7vh'}}/>
                    </div>
                </div>
            </div>
        )}
        {window.innerWidth <= 430 && (
            <div>
                <div className={css('map')} onClick={changePage}>
                    <img src='/Assets/Images/MDmap_3.png' style={{width: '35vh', height: '35vh'}} alt='Click vào để xem'/>
                </div>
                <div className={css('list-item')}>
                    <div className={css('item')} onClick={() => handleClickNumberSection(1)}>1. Giới thiệu</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(2)}>2. Hiện trạng sạt lở bờ sông Tiền , sông Hậu</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(3)}>3. Mục tiêu và các nội dung chính</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(4)}>4. Ưu nhược điểm các công trình bảo vệ bờ</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(5)}>5. Tuyến chỉnh trị, hành lang an toàn</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(6)}>6. Kết luận kiến nghị</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(7)}>7. Tài liệu</div>
                    <div className={css('item')} onClick={() => handleClickNumberSection(8)}>8. Công cụ giải pháp</div>
                </div>
            </div>
        )}
        {numberSection!==0 &&(
            <div className={css('group-detail')}>
                <FontAwesomeIcon className={css('icon')} icon={faClose} onClick={() => handleClickNumberSection(0)}/>
                {numberSection===1 &&(<Section1/>)}
                {numberSection===2 &&(<Section2/>)}
                {numberSection===3 &&(<Section3/>)}
                {numberSection===4 &&(<Section4/>)}
                {numberSection===5 &&(<Section5/>)}
                {numberSection===6 &&(<Section6/>)}
                {numberSection===7 &&(<Section7/>)}
                {numberSection===8 &&(<Section8/>)}
            </div>)}
    </div>);
};

export default Introduction;