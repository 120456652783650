import { appConfig } from '~/Config/AppConfig';
import restful from '~/Config/RestConfig';
import axios from 'axios';

const path = appConfig.API + 'api/v1/solution/';
class Solution {
    addSolution(formData) {
        return axios.post(path+'add-solution/',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    updateSolution(formData) {
        return axios.put(path+'update-solution/',formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(response=> response.data)
        .catch(error => error.response.data);
    };

    deleteSolution(Id) {
        return axios.delete(path+'delete-solution/'+Id)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    getSolution() {
        return axios.get(path+'get-solution/')
        .then(response=> response.data)
        .catch(error => error.response.data);
    };
    
    getSolutionByPage(pageIndex, pageSize){
        return axios.get(path+'get-solution-by-page/'+`${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    countSolution(){
        return axios.get(path+'count-solution/')
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    findSolution(keyword, pageIndex, pageSize){
        return axios.get(path+`find-solution/${keyword}/${pageIndex}/${pageSize}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };

    countFindSolution(keyword){
        return axios.get(path+`count-find-solution/${keyword}`)
        .then(response => response.data)
        .catch(error => error.response.data);
    };
}

export default Solution;