import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyles from './Components/GlobalStyles';
// import { NotificationProvider } from './Components/Notification/NotificationContext';
import { LoadingProvider } from './Components/Loading/LoadingContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//     <React.StrictMode>
//         <GlobalStyles>
//             <LoadingProvider>
//                 <NotificationProvider>
//                     <App />
//                 </NotificationProvider>
//             </LoadingProvider>
//         </GlobalStyles>
//     </React.StrictMode>
// );

root.render(
    <React.StrictMode>
        <GlobalStyles>
            <App />
        </GlobalStyles>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();