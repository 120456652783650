import React, { useEffect, useRef, useState } from 'react';
import className from 'classnames/bind';
import styles from './PopupMenu.module.scss';
import Toast from '~/Components/Controls/Toast';
import MediaAPI from '~/Api/Media';
import UserApi from '~/Api/User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import Loading from '~/Components/Controls/Loading';

const css = className.bind(styles);

function PopupMenu({userId, position, rename, deleteFile, page = 'Tree', closePop, openSolution, coordinates}){
    const divRef = useRef(null);
    const fileImageRef = useRef(null);
    const fileVideoRef = useRef(null);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [fileImage, setFileImage] = useState(null);
    const [fileVideo, setFileVideo] = useState(null);
    const [fileName, setFileName] = useState('');
    const [isOpenXN, setIsOpenXN] = useState(false);
    const [type, setType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    
    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handlGetFileImage = (event) => {
        const file = event.target.files[0];
        setFileImage(file);
        setFileName(file.name);
        setType(0);
    };

    const handleGetFileVideo = (event) => {
        const file = event.target.files[0];
        setFileVideo(file);
        setFileName(file.name);
        setType(1);
    };

    const handleClickUploadImage = () => {
        fileImageRef.current.click();
    };

    const handleClickUploadVideo = () => {
        fileVideoRef.current.click();
    };
    
    const handleOutsideClick = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleUploadFile = () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('Position',coordinates.toString());
        formData.append('Type',type===0?'image':'video');
        formData.append('dataFile',type===0?fileImage:fileVideo);

        const media = new MediaAPI();
        media.uploadMedia(formData)
        .then((response) => {
            if(response.status == 200) {
                setIsLoading(false);
                setMessage('Upload thành công!');
                setTypeToast('success');
                setShowToast(true);
                handleCloseFormUploadFile();
            } else {
                setIsLoading(false);
                setMessage(response.detail);
                setTypeToast('dangerous');
                setShowToast(true);
                handleCloseFormUploadFile();
            }
        });   
    };

    const handleRename = () => {
        setIsOpen(false);
        rename({id: position.id, open: true});
    };

    const handleDeleteFile = () => {
        deleteFile({id: position.id, delete: true});
    };

    const handleOpenPriceBySolution = () => {
        openSolution(true);
        setIsOpen(false);
    };

    const handleCloseFormUploadFile = () => {
        setIsOpenXN(false);
        setFileImage(null);
        setFileVideo(null);
        setFileName('');
    };

    useEffect(() => {
        setIsOpen(false);
    },[closePop])
    
    useEffect(() => {
        setIsOpen(true); 
        const user = new UserApi();
        if(userId){
            user.getNguoiDungByUserId(userId)
            .then(response => {
                if(response.status === 200){
                    if(response.data.roleName === 'Administrator' || response.data.roleName === 'Operator'){
                        setIsUpdate(true);
                    }
                }
            });
        }
    }, [position]);

    useEffect(() => {
        if(fileImage!==null){
            setIsOpenXN(true);
            setType(0);
        }
    },[fileImage]);

    useEffect(() => {
        if(fileVideo!==null){
            setIsOpenXN(true);
            setType(1);
        }
    },[fileVideo]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    return (<>
        {page === 'Tree' && (
            <div ref={divRef}>
                {isOpen && position && (
                    <div className={css('popup-menu')} style={{left: `${position.x/10}rem`, top: `${position.y/10}rem`}}>
                        <div className={css('item')} onClick={handleRename}>
                            <p>Đổi tên</p>
                        </div>
                        <div className={css('item')} onClick={handleDeleteFile}>
                            <p>Xóa</p>
                        </div>
                    </div>
                )}
            </div>
        )}
        {page === 'GoogleMap' && (
            <div ref={divRef}>
                {isOpen && position && (
                    <div className={css('popup-menu')} style={{left: `${position.x/10}rem`, top: `${position.y/10}rem`}}>
                        <div className={css('item')} onClick={handleOpenPriceBySolution}>
                            <p>Xem giải pháp</p>
                        </div>
                        {isUpdate && (
                            <>
                                <div className={css('item')} onClick={handleClickUploadImage}>
                                    <p>Cập nhật hình ảnh</p>
                                    <input type='file' style={{ display: 'none' }} ref={fileImageRef} onChange={handlGetFileImage}/>
                                </div>
                                <div className={css('item')} onClick={handleClickUploadVideo}>
                                    <p>Cập nhật video</p>
                                    <input type='file' style={{ display: 'none' }} ref={fileVideoRef} onChange={handleGetFileVideo} />
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        )}
        <Toast
            message={message}
            onClose={handleCloseToast}
            showToast={showToast}
            type={typeToast}
            duration={2000}
        />
        {isOpenXN && (
            <div className={css('form-upload-file')}>
                <div className={css('background-title')}>
                    <p className={css('title')}>
                        Xác nhận
                    </p>
                    <FontAwesomeIcon icon={faRemove} className={css('remove')} onClick={handleCloseFormUploadFile}/>
                </div>
                <div className={css('form-body')}>
                    <p className={css('detail')}>Bạn có muốn upload file <p style={{fontWeight: 'bold'}}>{fileName}</p> không?</p>
                    <div className={css('btn-group')}>
                        <div className={css('btn','success')} onClick={handleUploadFile}>Tải lên</div>
                        <div className={css('btn','cancel')} onClick={handleCloseFormUploadFile}>Hủy</div>
                    </div>
                </div>
            </div>
        )}
        <Loading isOpen={isLoading}/>
    </>);
}

export default PopupMenu;