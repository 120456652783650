import React, { useEffect, useState } from 'react';
import className from 'classnames/bind';
import styles from './FormSetting.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import Geojson from '~/Api/Geojson';
import Note from '~/Api/Note';
import Toast from '~/Components/Controls/Toast';
import Loading from '~/Components/Controls/Loading';

const css = className.bind(styles);

function FormSetting ({id}) {
    const [isOpen, setIsOpen] = useState(false);
    const [label, setLabel] = useState(false);
    const [warning, setWarning] = useState(false);
    const [icon, setIcon] = useState('');
    const [color, setColor] = useState(null);
    const [isChange, setIsChange] = useState(false);
    const [left, setLeft] = useState(null);
    const [top, setTop] = useState(null);
    const [listNote, setListNote] = useState([]);
    const [message, setMessage] = useState('');
    const [typeToast, setTypeToast] = useState('success');
    const [showToast, setShowToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleOnChangeLabel = () => {
        setLabel(!label);
    };

    const handleOnChangeWarning = () => {
        setWarning(!warning);
    };

    const handleColorChange = (event) => {
        setColor(event.target.value);
    };

    const handleClickChange = (event) => {
        const left = event.clientX;
        const top = event.clientY;
        setLeft(left);
        setTop(top);
        setIsChange(true);
        const note = new Note();
        note.getListNote().then((value) => {
            if(value.status === 200){
                setListNote(value.data);
            }
        });
    };

    const handleSave = () => {
        const formData = new FormData();
        formData.append('Id',id);
        if(icon){
            formData.append('Icon',icon);
        }
        formData.append('Color',color);
        formData.append('IsLabel',label?'true':'false');
        formData.append('IsWarning',warning?'true':'false');
        const geojson = new Geojson();
        geojson.updateToolByFileId(formData)
        .then((value) => {
            if(value.status === 200) {
                setMessage('Cập nhật thành công.');
                setTypeToast('success');
                setShowToast(true);
                let d = value.data;
                if(d!==null){
                    setIcon(d.icon);
                    setColor(d.colorsLine);
                    setLabel(d.isLabel!=='false'?true:false);
                    setWarning(d.isWarning!=='false'?true:false);
                }
            } else {
                setMessage(value.detail);
                setTypeToast('dangerous');
                setShowToast(true);
            }
        });
    };

    const handleCloseChange = () => {
        setIsChange(false);
    };

    useEffect(() => {
        if(id!==0){
            setIsLoading(true);
            const geojson = new Geojson();
            geojson.getGeojsonByFileId(id).then((value) => {
                if(value.status === 200){
                    let d = value.data;
                    setIcon(d.icon);
                    setColor(d.colorsLine);
                    setLabel(d.isLabel!=='false'?true:false);
                    setWarning(d.isWarning!=='false'?true:false);
                    setIsOpen(true);
                    setIsLoading(false);
                }
            });
        }
    },[id]);

    return(
        <div>
            <Toast
                message={message}
                onClose={handleCloseToast}
                showToast={showToast}
                type={typeToast}
                duration={2000}
            />
            <Loading isOpen={isLoading}/>
            {isOpen&&(
                <div className={css('form-setting')}>
                    <div className={css('background-title')}>
                        {/* <p className={css('title')}></p> */}
                        <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleOpen}/>
                    </div>
                    <div className={css('form-body')}>
                        <p className={css('title')}>THIẾT LẬP TẬP TIN</p>
                        <div className={css('item')}>
                            <p className={css('label')}>Biểu tượng</p>
                            {icon!==null && (
                                <img className={css('image')} src={icon} />
                            )}
                            {icon==null && (
                                <p>Trống</p>
                            )}
                            <p className={css('txt_change')} onClick={handleClickChange}>Thay đổi</p> 
                        </div>
                        <div className={css('item')}>
                            <p className={css('label')}>Màu sắc</p>
                            {color!==null && (
                                <input className={css('color-input')} type='color' value={color} onChange={handleColorChange}/>
                            )}
                        </div>
                        <div className={css('item')}>
                            <p className={css('label')}>Nhãn</p>
                            <input className={css('ipt_checkbox')} type='checkbox' checked={label} onChange={handleOnChangeLabel}/>
                            <p>{label?'Mở':'Đóng'}</p>
                        </div>    
                        <div className={css('item')}>
                            <p className={css('label')}>Cảnh báo</p>
                            <input className={css('ipt_checkbox')} type='checkbox' checked={warning} onChange={handleOnChangeWarning}/>
                            <p>{warning?'Mở':'Đóng'}</p>
                        </div>
                    </div>
                    <div className={css('btn-group')}>
                        <div className={css('btn','success')} onClick={handleSave}>Lưu</div>
                        <div className={css('btn','cancel')} onClick={handleCloseChange}>Hủy</div>
                    </div>
                    {isChange && (
                        <div className={css('form-change')} style={{top: top, left: left}}>
                            <FontAwesomeIcon className={css('remove')} icon={faRemove} onClick={handleCloseChange}/>
                            <div className={css('list-icon')}>
                                {listNote.length>0&&(
                                    listNote.map((res, idx) => (
                                        <div className={css('item-icon')}>
                                            <img className={css('image')} src={res.path} style={{marginRight: '1rem'}}/>
                                            <p>{res.name}</p>
                                        </div>
                                    ))
                                )}
                            </div>   
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default FormSetting;